.introduce-container {
    padding: px2rem(114px) 0 px2rem(130px);
    background-color: #ebebeb;
    .intro-content {
        display: flex;
        width: 100%;
        @media screen and (max-width: 750px) {
            display: block;
        }
        .intro-img {
            width: (609 / 1500) * 100%;
            display: flex;
            align-items: center;
            @media screen and (max-width: 750px) {
                width: 100%;
            }
            .img {
                position: relative;
                width: 100%;
                padding-bottom: (443 / 609) * 100%;
                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    height: auto;
                }
            }
        }
        .intro-info {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 75px;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 30px;
            color: #777;
            @media screen and (max-width: 750px) {
                padding-left: 0px;
                margin-top: 10px;
            }
            .name {
                font-size: px2rem(30px);
                line-height: px2rem(48px);
                color: #333;
                @media screen and (max-width: 750px) {
                    font-size: 18px;
                    line-height: 28px;
                }
            }
            .dsc {
                margin-top: px2rem(22px);
                font-size: 14px;
                line-height: 30px;
                color: #777;
                @media screen and (max-width: 750px) {
                    font-size: 13px;
                    line-height: 20px;
                    margin-top: 5px;
                }
            }
            .num {
                display: flex;
                .box {
                    margin-top: px2rem(35px);
                    margin-right: px2rem(50px);
                    text-align: center;
                    .sz {
                        position: relative;
                        font-family: Arial;
                        font-size: px2rem(60px);
                        line-height: px2rem(45px);
                        font-weight: bold;
                        color: #3c98b8;
                        span {
                            display: inline-block;
                            position: relative;
                        }
                        span:before {
                            content: '+';
                            position: absolute;
                            top: 50%;
                            right: -16px;
                            transform: translateY(-50%);
                            font-size: 18px;
                            font-weight: bold;
                            color: #3c98b8;
                        }
                    }

                    .zw {
                        font-size: 14px;
                        line-height: 30px;
                        color: #777;
                        @media screen and (max-width: 500px) {
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                }
                .box:nth-of-type(1) {
                    span:before {
                        content: none;
                    }
                }
            }
        }
    }
    .intro-sp {
        margin-top: px2rem(100px);
        cursor: pointer;
        img {
            max-width: 100%;
            height: auto;
        }
    }
}