.footer {
    background-color: #1b1b1b;
    padding-top: 90px;
    padding-bottom: 67px;
    overflow: hidden;
    @media screen and (max-width: 1600px) {
        padding-top: 60px;
        padding-bottom: 37px;
    }
    @media screen and (max-width: 750px) {
        padding-top: px2rem(74px);
        padding-bottom: px2rem(230px);
    }
    .wp1600 {
        display: flex;
        .left {
            width: (510 / 1600) * 100%;
            padding-right: 178px;
            box-sizing: border-box;
            @media screen and (max-width: 1600px) {
                padding-right: 80px;
            }
            @media screen and (max-width: 1260px) {
                padding-right: 30px;
            }
            @media screen and (max-width: 750px) {
                display: none;
            }
            .lxwm {
                border-bottom: 1px dashed #565656;
                padding-bottom: px2rem(18px);
                @media screen and (max-width: 750px) {
                    border-bottom: none;
                    padding-bottom: 0px;
                }
                .name {
                    font-size: 30px;
                    line-height: 30px;
                    font-family: Arial;
                    color: #fff;
                }
                p {
                    font-size: 13px;
                    line-height: 22px;
                    color: #b3b3b3;
                    text-transform: uppercase;
                }
                .phone {
                    margin-top: 15px;
                    a {
                        color: #b3b3b3;
                    }
                }
                .em {
                    a {
                        color: #b3b3b3;
                    }
                }
                a:hover {
                    color: #fff;
                    text-decoration: underline;
                }
            }
            .erweima {
                margin-top: 22px;
                .img {
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
                .dsc {
                    font-size: 13px;
                    line-height: 30px;
                    color: #777;
                }
            }
        }
        .right {
            flex: 1;
            @media screen and (max-width: 750px) {
                display: none;
            }
            .footer-nav {
                display: flex;
                dl {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-left: 1px solid #272727;
                    dt {
                        a {
                            font-size: 16px;
                            line-height: 16px;
                            color: #fff;
                        }
                    }
                    dd {
                        margin-top: 15px;
                        a {
                            font-size: 13px;
                            line-height: 13px;
                            color: #888;
                        }
                    }
                    dd:nth-of-type(1) {
                        margin-top: 21px;
                    }
                    a:hover {
                        color: #fff;
                        text-decoration: underline;
                    }
                }
                dl:nth-last-of-type(1) {
                    border-right: 1px solid #272727;
                }
            }
        }
        .mobile-info {
            display: none;
            color: #fff;
            width: 100%;
            @media screen and (max-width: 750px) {
                display: block;
            }
            .info-box {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
            .info-dz {
                margin-top: px2rem(22px);
                padding-bottom: px2rem(40px);
                border-bottom: 1px dashed #565656;
                .dz {
                    font-size: px2rem(26px);
                    line-height: px2rem(26px);
                    color: #b3b3b3;
                    text-transform: uppercase;

                }
            }
            .info-left {
                .name {
                    font-size: px2rem(36px);
                    line-height: px2rem(36px);
                    font-family: Arial;
                    color: #fff;
                    text-transform: uppercase;
                    margin-bottom: px2rem(30px);
                }
                p {
                    font-size: px2rem(26px);
                    line-height: px2rem(26px);
                    color: #b3b3b3;
                    text-transform: uppercase;
                    margin-bottom: px2rem(22px);
                }
                a {
                    display: block;
                    font-size: px2rem(26px);
                    line-height: px2rem(26px);
                    color: #b3b3b3;
                    text-transform: uppercase;
                    margin-bottom: px2rem(22px);
                }
                a:hover {
                    text-decoration: underline;
                    color: #fff;
                }
                a:nth-last-of-type(1) {
                    margin-bottom: 0px;
                }

            }
            .erweima {
                margin-right: px2rem(20px);
                .img {
                    width: px2rem(130px);
                    height: px2rem(130px);
                    margin: 0 auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .dsc {
                    font-size: px2rem(20px);
                    line-height: px2rem(40px);
                    color: #777;
                    text-align: center;
                }
            }
        }
    }
    .footer-bottom {
        margin-top: px2rem(21px);
        padding-left: (660 / 1920 ) * 100%;
        padding-right: 10px;
        overflow: hidden;
        @media screen and (max-width: 750px) {
            padding: 0px px2rem(30px);
        }
        .yqlj {
            @media screen and (max-width: 750px) {
                display: none;
            }
            span {
                font-size: 14px;
                line-height: 30px;
                color: #666;
                margin-right: 15px;
                a {
                    font-size: 14px;
                    line-height: 30px;
                    color: #666;
                }
                a:hover {
                    color: #fff;
                    text-decoration: underline;
                }
            }
            .name {
                margin-right: 10px;
            }
        }
        .ba {
            font-size: 14px;
            line-height: 30px;
            color: #666;
            @media screen and (max-width: 750px) {
                font-size: px2rem(26px);
                line-height: px2rem(26px);
            }
            span {
                @media screen and (max-width: 750px) {
                    display: block;
                    margin-bottom: px2rem(22px);
                }
            }
            a {
                font-size: 14px;
                line-height: 30px;
                color: #666;
                @media screen and (max-width: 750px) {
                    display: block;
                    font-size: px2rem(26px);
                    line-height: px2rem(26px);
                    margin-bottom: px2rem(22px);
                }
            }
            img {
                margin-left: 10px;
                vertical-align: sub;
            }
        }
    }

    .fixed-bottom {
        display: none;
        position: fixed;
        box-sizing: border-box;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: px2rem(152px);
        background-color: #2b2b2b;
        z-index: 999;
        @media screen and (max-width: 750px) {
            display: block;
        }
        .btn-box {
            display: flex;
            height: 100%;
            width: 100%;
            .btn {
                box-sizing: border-box;
                border-left: 1px solid #3f3f3f;
                border-right: 1px solid #3f3f3f;
                width: 25%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                a {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                span {
                    i {
                        font-size: px2rem(50px);
                        color: #fff;

                    }
                    font-size: 26px;
                    color: #fff;
                    @media screen and (max-width: 510px) {
                        font-size: 18px;
                    }
                    @media screen and (max-width: 500px) {
                        font-size: 16px;
                    }
                    @media screen and (max-width: 400px) {
                        font-size: 14px;
                    }
                }
                span:nth-of-type(1) {
                    position: relative;
                }

                span:nth-of-type(1):before {
                    position: absolute;
                    bottom: px2rem(-10px);
                    left: 50%;
                    transform: translateX(-50%);
                    content: '';
                    width: px2rem(21px);
                    height: 2px;
                    background: linear-gradient(#2f82ac, #51a5b1);
                }
                span:nth-of-type(2) {
                    margin-top: px2rem(15px);
                }
            }

        }
    }
}

.right-bar {
    position: fixed;
    bottom: 5%;
    right: 0;
    text-align: center;
    z-index: 1000;
    color: #666;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, .1);
    border-right: 1px solid #fff;
    @media screen and (max-width: 1000px) {
        display: none;
    }
    li {
        padding: px2rem(8px) 0px px2rem(6px);
        background-color: #fff;
        font-size: 13px;
        border-bottom: 1px solid #cacaca;
        box-sizing: border-box;
        color: #666;
        display: flex;
        justify-content: center;
        .box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .img {
                max-width: 100%;
            }
            p {
                margin-top: 5px;
                max-width: 64px;
            }
        }
    }
    li:nth-last-of-type(1) {
        border-bottom: none;
    }
    li:hover {
        background-color: $baseColor;
        p {
            color: #fff;
        }
        .icon {
            color: #fff;
        }
    }
    .icon {
        font-size: px2rem(36px);
    }
    .box {
        color: #666;
        display: block;
        padding: px2rem(0px) px2rem(5px) px2rem(0px);
        cursor: pointer;
    }
    .fwh {
        position: relative;
        .fwh-ewm {
            display: none;
            position: absolute;
            top: 0px;
            right: 75px;
            width: 96px;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .rxdh {
        position: relative;
        .rxdh-num {
            display: none;
            position: absolute;
            bottom: -20px;
            right: 78px;
            width: 222px;
            height: 95px;
            background-image: url(../images/phone.png);
            background-size: 100% 100%;
            p {
                width: 100%;
                line-height: 85px;
                font-size: 26px;
                font-family: Arial;
                color: $baseColor;
            }
        }
    }
    .rxdh:hover {
        .rxdh-num {
            display: block;
        }
    }
    .fwh:hover {
        .fwh-ewm {
            display: block;
        }
    }
}