.project_info-container {
    padding: px2rem(75px) 0 px2rem(108px);
    background-color: #ebebeb;
    .pro-content {
        position: relative;
        background-color: #fff;
        box-sizing: border-box;
        padding: 90px 165px;
        border-radius: px2rem(10px);
        @media screen and (max-width: 1500px) {
            padding: px2rem(50px) px2rem(80px);
        }
        .pro-container {
            position: relative;
            width: 100%;
        }
        .swiper-slide {
            .img {
                position: relative;
                width: 100%;
                padding-bottom: (720 / 1170) * 100%;
                box-sizing: border-box;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    height: auto;
                }
            }
        }
        .img-name {
            margin-top: px2rem(34px);
            padding-bottom: px2rem(18px);
            margin-bottom: px2rem(22px);
            border-bottom: 1px dashed #bbb;
            font-size: 24px;
            line-height: 30px;
            font-weight: bold;
            color: #333;
            @media screen and (max-width: 750px) {
                font-size: 18px;
                line-height: 26px;
            }
        }
        .img-dsc {
            font-size: 14px;
            line-height: 30px;
            color: #777;
            padding-right: px2rem(86px);
            @media screen and (max-width: 750px) {
                font-size: 13px;
                line-height: 24px;
                padding-right: px2rem(30px);
            }
        }
        .swiper-button-next1 {
            right: -67px;
            @media screen and (max-width: 1500px) {
                right: -50px;
            }
            @media screen and (max-width: 750px) {
                right: px2rem(-70px);
            }
        }
        .swiper-button-prev1 {
            left: -67px;
            @media screen and (max-width: 1500px) {
                left: -50px;
            }
            @media screen and (max-width: 750px) {
                left: px2rem(-70px);
            }
        }
        .swiper-button-next1,
        .swiper-button-prev1 {
            width: 57px;
            height: 57px;
            background: linear-gradient(#2f82ac, #51a5b1); /*标准语法，必须放在最后*/
            outline: none;
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            cursor: pointer;
            @media screen and (max-width: 1500px) {
                width: 40px;
                height: 40px;
            }
            @media screen and (max-width: 750px) {
                width: px2rem(60px);
                height: px2rem(60px);
            }

        }
        .swiper-button-disabled {
            opacity: 1;
            cursor: auto;
            //pointer-events: none
        }
        .swiper-button-prev1:before,
        .swiper-button-next1:before {
            position: absolute;
            font-size: 20px;
            color: #fff;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media screen and (max-width: 1000px) {
                font-size: px2rem(26px);
            }
        }
        .swiper-button-prev1:before {
            transform: translate(-50%, -50%) rotateY(180deg);
        }
        .swiper-button-next1:hover,
        .swiper-button-prev1:hover {
            background: linear-gradient(#0b80a8, #028695);
        }
        .swiper-button-next1:hover:before,
        .swiper-button-prev1:hover:before {
            color: #fff;
        }
    }
}