.news_list-container {
    background-color: #ebebeb;
    padding: px2rem(42px) 0 px2rem(67px);
}
.news-list {
    padding-bottom: px2rem(78px);
    li {
        position: relative;
        &:after {
            position: absolute;
            display: block;
            width: 100%;
            height: 95px;
            background: url(../images/shadow_1.png) center bottom no-repeat;
            background-size: 100% auto;
            left: 0;
            bottom: -55px;
            z-index: 1;
            content: '';
            opacity: .2;
            @media screen and (max-width: 750px) {
                content: none;
            }
        }
        &:hover {

            .img {
                opacity: .8;
            }
            .right-box {
                .box-top {
                    .info {
                        h3 {
                            text-indent: .5em;
                            color: $baseColor;
                        }
                    }
                    .time {
                        background: linear-gradient($baseColor, $baseColor);
                    }
                }
            }

        }
        .news-box {
            position: relative;
            display: flex;
            background-color: #fff;
            padding: 41px 48px 43px 45px;
            overflow: hidden;
            z-index: 5;
            @media screen and (max-width: 750px) {
                padding: px2rem(30px);
            }
        }
        .img {
            width: 235px;
            @media screen and (max-width: 750px) {
                width: 150px;
            }
            @media screen and (max-width: 500px) {
                width: 100px;
            }
            span {
                display: block;
                width: 100%;
                padding-bottom: (185 / 186) * 100%;
                position: relative;
            }
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
        .right-box {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .box-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-left: px2rem(28px);
                .info {

                    margin-right: 70px;
                    @media screen and (max-width: 750px) {
                        margin-right: px2rem(30px);
                    }
                }
                h3 {
                    font-size: 22px;
                    line-height: 24px;
                    height: 24px;
                    overflow: hidden;
                    color: #333;
                    margin-bottom: 22px;
                    transition: all .5s ease;
                    @media screen and (max-width: 500px) {
                        font-size: 16px;
                        line-height: 18px;
                        height: 18px;
                        margin-bottom: 10px;
                    }
                }
                .p {
                    font-size: 14px;
                    line-height: 28px;
                    color: #777;
                    height: 84px;
                    overflow: hidden;
                    @media screen and (max-width: 500px) {
                        font-size: 13px;
                        line-height: 20px;
                        height: 60px;
                    }
                }
                .time {
                    flex-shrink: 0;
                    width: 100px;
                    height: 100px;
                    color: #fff;
                    background: linear-gradient(#2f83ae, #52bac8);
                    text-align: center;
                    font-family: "Arial";
                    transition: all .2s ease;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    @media screen and (max-width: 750px) {
                        display: none;
                    }
                }
                .year {
                    text-align: center;
                    font-size: 14px;
                }
                .date {
                    text-align: center;
                    font-size: 36px;
                }
            }
            .box-bottom {
                margin-left: 28px;
                border-top: 1px dashed #c6c6c6;
                padding-top: 20px;
                margin-top: 21px;
                @media screen and (max-width: 750px) {
                    display: none;
                }
                span {
                    font-size: 14px;
                    line-height: 14px;
                    color: #333;
                }
                a {
                    font-size: 14px;
                    line-height: 14px;
                    color: #333;
                }
                a:hover {
                    color: $baseColor;
                }
            }
        }

    }
    li + li {
        margin-top: px2rem(40px);
    }
}