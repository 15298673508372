.process-container {
    padding: px2rem(64px) 0 px2rem(86px);
    background-color: #ebebeb;
    .process-content {
        display: flex;
        flex-wrap: wrap;
        margin: px2rem(-14px);
        overflow: hidden;
        .content-box {
            width: 25%;
            box-sizing: border-box;
            padding: px2rem(14px);
            @media screen and (max-width: 1260px) {
                width: 33.3%;
            }
            @media screen and (max-width: 750px) {
                width: 50%;
            }
            .img {
                width: 100%;
                position: relative;
                padding-bottom: (243 / 353) * 100%;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    height: auto;
                }
            }
            .img-dsc {
                background-color: #fff;
                height: 241px;
                padding: 0 px2rem(30px);
                @media screen and (max-width: 750px) {
                    padding: 0 px2rem(20px);
                    height: 210px;
                }
                @media screen and (max-width: 370px) {
                    height: 240px;
                }
                .circle {
                    position: relative;
                    width: 82px;
                    height: 82px;
                    background: linear-gradient(#2f83ae, #52bac8);
                    font-size: 18px;
                    font-family: Arial;
                    color: #fff;
                    text-align: center;
                    line-height: 90px;
                    border-radius: 50%;
                    margin: -50px auto 0;
                    @media screen and (max-width: 1260px) {
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        margin-top: -30px;
                    }
                    @media screen and (max-width: 750px) {
                        font-size: 14px;
                    }
                }
                .name {
                    margin-top: px2rem(16px);
                    margin-bottom: px2rem(15px);
                    font-size: 18px;
                    line-height: 18px;
                    color: #333;
                    text-align: center;
                    @media screen and (max-width: 750px) {
                        font-size: 16px;
                        line-height: 20px;
                    }
                    @media screen and (max-width: 370px) {
                        font-size: 16px;
                        line-height: 20px;
                        height: 40px;
                    }
                }
                .dsc {
                    margin: 0 auto;
                    width: 100%;
                    max-width: 238px;
                    font-size: 14px;
                    line-height: 24px;
                    color: #888;
                    text-align: center;
                    @media screen and (max-width: 750px) {
                        font-size: 13px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}

