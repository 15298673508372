.honor-container {
    padding: px2rem(70px) 0 px2rem(60px);
    background-color: #ebebeb;
    .wp1500 {
        overflow: hidden;
    }
    .honor-content {
        display: flex;
        flex-wrap: wrap;
        //width: 100%;
        margin: px2rem(-15px);
        overflow: hidden;
        padding-bottom: px2rem(70px);
        .box {
            display: block;
            width: 25%;
            box-sizing: border-box;
            padding: px2rem(15px);
            cursor: pointer;
            @media screen and (max-width: 750px) {
                width: 50%;
            }
            .box-content {
                width: 100%;
                background-color: #fff;
                padding: 45px 78px 72px;
                box-sizing: border-box;
                @media screen and (max-width: 1500px) {
                    padding: px2rem(60px) px2rem(60px) px2rem(54px);
                }

                @media screen and (max-width: 1260px) {
                    padding: px2rem(40px) px2rem(30px) px2rem(45px);
                }

                @media screen and (max-width: 500px) {
                    padding: px2rem(60px) px2rem(30px) px2rem(45px);
                }

                @media screen and (max-width: 380px) {
                    padding: px2rem(80px) px2rem(30px) px2rem(45px);
                }

                .img {
                    position: relative;
                    width: 100%;
                    padding-bottom: (162 / 188) * 100%;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        height: auto;
                    }
                }
                .name {
                    margin-top: px2rem(50px);
                    font-size: 16px;
                    line-height: 16px;
                    color: #333;
                    text-align: center;
                    @media screen and (max-width: 380px) {
                        margin-top: 40px;
                    }
                }
                .circle {
                    width: 32px;
                    height: 32px;
                    margin: px2rem(15px) auto 0;
                    border-radius: 50%;
                    font-size: 14px;
                    line-height: 32px;
                    text-align: center;
                    background: linear-gradient(#ebebeb, #ebebeb);
                }
            }
        }
        .box:hover {
            .box-content {
                box-shadow: 5px 5px 20px rgba(0, 0, 0, .2);
                .circle {
                    background: linear-gradient(#2f83ae, #52bac8);
                    color: #fff;
                }
            }
        }
    }
}

.click-block {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .8);
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    .img-box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .img {
            position: relative;
            width: 100%;
            max-width: (720 / 1920 ) * 100%;
            background-color: #fff;
            box-sizing: border-box;
            padding: px2rem(68px) px2rem(140px) px2rem(62px);
            @media screen and (max-width: 1500px) {
                padding: px2rem(50px) px2rem(100px) px2rem(42px);
            }
            @media screen and (max-width: 1400px) {
                padding: px2rem(50px) px2rem(60px) px2rem(42px);
            }
            @media screen and (max-width: 1000px) {
                max-width: 80%;
            }
            .img-pad {
                position: relative;
                width: 100%;
                padding-bottom: (597 / 460) * 100%;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: block;
                    max-width: 100%;
                    height: auto;
                }
            }
            .img-dsc {
                text-align: center;
                font-size: 24px;
                line-height: 24px;
                margin-top: px2rem(48px);
                color: #333;
                @media screen and (max-width: 1000px) {
                    font-size: 18px;
                }
            }
        }
        .guanbi {
            position: absolute;
            top: 8px;
            right: 12px;
            cursor: pointer;
            z-index: 999;
            i {
                font-size: 21px;
                color: #888;
            }
            @media screen and (max-width: 1100px) {
                top: 10px;
                right: 10px;
            }
            @media screen and (max-width: 700px) {
                top: 0px;
                right: 0px;
            }
        }
        .guanbi:hover {
            i {
                color: $baseColor;
            }
        }
    }
}