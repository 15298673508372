.witness-container {

    .witness-show {
        padding: 46px 0 px2rem(102px);
        box-sizing: border-box;
        background-color: #ebebeb;
    }
    .witness-bk {
        background: url(../images/witnessbk_02.png) center center;
        background-size: cover;
        height: px2rem(565px);
        width: 100%;
        position: relative;
        z-index: 2;
    }
    .witness-display {
        background-color: #ebebeb;
        .wp1500 {
            position: relative;
            margin-top: px2rem(-460px);
            z-index: 9;
            overflow: hidden;
            .witness-name {
                color: #fff;
            }
            .witness-en {
                color: #fff;
            }
        }
        .witness-content {
            background-color: #fff;
            .dsc {
                height: 60px;
            }
        }
        .display-mar {
            margin: px2rem(62px) -33px -15px;
            padding: 57px 89px;

            @media screen and (max-width: 1260px) {
                padding: 30px 50px;
                margin: px2rem(62px) -10px -10px;
            }
            @media screen and (max-width: 750px) {
                padding: 30px px2rem(10px);
                margin: px2rem(62px) 0px -10px;
            }
        }
    }

    .witness-mc {
        padding: px2rem(72px) 0px px2rem(81px);
        background-color: #ebebeb;
    }

    .witness-name {
        font-size: 30px;
        line-height: 30px;
        color: #333;
        font-weight: bold;
        text-align: center;
        @media screen and (max-width: 750px) {
            font-size: 22px;
            line-height: 22px;
        }
    }
    .witness-en {
        margin-top: px2rem(7px);
        font-size: 18px;
        line-height: 18px;
        font-family: Arial;
        color: #888;
        text-align: center;
        text-transform: uppercase;
        @media screen and (max-width: 750px) {
            font-size: 14px;
            line-height: 14px;
        }
    }
    .witness-content {
        padding: 0 92px;
        box-sizing: border-box;
        margin: px2rem(20px) -33px -15px;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: 1260px) {
            padding: 0 50px;
            margin: px2rem(25px) -10px -10px;
        }

        @media screen and (max-width: 750px) {
            padding: 0 px2rem(30px);
        }
        .content-box {
            width: 33.3%;
            box-sizing: border-box;
            padding: 15px 33px;
            @media screen and (max-width: 1260px) {
                padding: 10px;
            }
            @media screen and (max-width: 750px) {
                width: 50%;
            }
            .img {
                width: 100%;
                position: relative;
                padding-bottom: (243 / 394) * 100%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    height: auto;
                    transition: all .3s;
                }
            }
            .dsc {
                position: relative;
                width: 100%;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                transition: all .2s;
                padding: 0 px2rem(20px);
                box-sizing: border-box;
                @media screen and (max-width: 500px) {
                    height: 70px;
                }
                span {
                    display: inline-block;
                    font-size: 16px;
                    line-height: 20px;
                    color: #666;
                    text-align: center;
                    @media screen and (max-width: 750px) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
        }
        .content-box:hover {
            .dsc {
                span {
                    color: $baseColor;
                }
            }
            .img {
                img {
                    transform: translate(-50%, -50%) scale(1.1);
                }

            }

        }
    }
}



