.cpzx_list-container {
    padding: px2rem(95px) 0 px2rem(78px);
    overflow: hidden;
    background-color: #ebebeb;
}

.pro-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -39px;
    .item {
        margin-bottom: 30px;
        padding: 0 39px 10px;
        width: 50%;
        box-sizing: border-box;
    }
    .box-list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        width: 50%;
        box-sizing: border-box;
        .item {
            margin-bottom: 0;
        }
    }
    a {
        display: block;
        &:hover {
            .img img {
                transform: scale(1.1);
            }
            h3 {
                color: $baseColor;
            }
        }
    }
    .img {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: (460 / 742) * 100%;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all .5s;
        }
    }
    h3 {
        line-height: 39px;
        font-size: 18px;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        transition: all .3s;
        padding-left: 4%;
        box-sizing: border-box;
    }
    @media screen and (max-width: 1440px) {
        margin: 0 -32px 20px;
        .item {
            margin-bottom: 20px;
            padding: 0 32px 16px;
        }
        .box-list {
            margin-bottom: 20px;
        }
        h3 {
            line-height: 45px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 0 -25px 20px;
        .item {
            margin-bottom: 20px;
            padding: 0 25px 12px;
        }
        .box-list {
            margin-bottom: 20px;
        }
        h3 {
            line-height: 36px;
            font-size: 12px;
        }
    }
    @media screen and (max-width: 750px) {
        margin: 0 px2rem(-15px) px2rem(15px);
        .item {
            margin-bottom: 0;
            padding: 0 px2rem(15px) 15px;
            &:nth-child(10n + 1),
            &:nth-child(10n + 6) {
                width: 100%;
            }
        }
        h3 {
            line-height: 35px;
            font-size: 13px;
        }
    }
}