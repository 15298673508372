.history-container {
    background-color: #ebebeb;
    padding: 91px 0 140px;
    @media screen and (max-width: 500px) {
        padding: 60px 0 80px;
    }
}
.history-lunbo {
    position: relative;
    z-index: 66;
    .history-list {
        position: relative;
        box-sizing: border-box;
        padding: 24px 0px 0px;
        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 0px;
            bottom: 0;
            margin-left: -.5px;
            border-left: 1px dashed #bfbfbf;
            z-index: 1;
        }
        .history-li {
            //height: auto !important;
            //margin-bottom: 20px;
            display: flex;
            align-items: center;
            &:nth-child(odd) {
                margin-bottom: -110px;
                .year {
                    order: 2;
                    padding-left: 40px;
                    &:before,
                    &:after {
                        right: 100%;
                    }
                    &:before {
                        margin-right: -13px;
                    }
                    &:after {
                        margin-right: -5px;
                    }
                }
                .box {
                    margin-right: 55px;

                }
            }
            &:nth-child(even) {
                margin-bottom: -38px;
                .year {
                    padding-right: 40px;
                    text-align: right;
                    &:before,
                    &:after {
                        left: 100%;
                    }
                    &:before {
                        margin-left: -13px;
                    }
                    &:after {
                        margin-left: -5px;
                    }
                }
                .box {
                    margin-left: 55px;
                }
            }
        }
        //.history-li:nth-of-type(6) {
        //    margin-bottom: 0px !important;
        //}
        .history-li:nth-last-of-type(1) {
            margin-bottom: 0px !important;
        }
        .year {
            font-family: Arial;
            font-weight: bold;
            position: relative;
            width: 50%;
            font-size: 34px;
            color: #333;
            box-sizing: border-box;
            z-index: 3;
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 50%;
                width: 10px;
                height: 10px;
                background: linear-gradient(#2f82ac, #51a5b1);
                z-index: 2;
            }
        }
        .box {
            position: relative;
            flex: 1;
            min-width: 0;
            display: flex;
            padding: 30px;
            background-color: #fff;
            border-radius: 5px;
        }
        .img {
            margin-right: 25px;
            width: 154px;
            height: 154px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .info {
            flex: 1;
            min-width: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .time {
            font-size: 18px;
            line-height: 30px;
            color: #333;
            margin-bottom: px2rem(6px);
            @media screen and (max-width: 750px) {
                font-size: 16px;
                line-height: 20px;
            }
            p {
                font-weight: bold;
            }
        }
        .p {
            font-size: 14px;
            line-height: 30px;
            color: #666;
            overflow: hidden;
            height: auto;
        }
        @media screen and (max-width: 1200px) {

            .year {
                font-size: 30px;
            }
            .box {
                padding: px2rem(20px);
            }
        }
        @media screen and (max-width: 1024px) {
            &:before {
                top: -20px;
            }
            .history-li {
                &:nth-child(odd) {
                    .box {
                        margin-right: 40px;
                    }
                }
                &:nth-child(even) {
                    .box {
                        margin-left: 40px;
                    }
                }
            }
            .year {
                font-size: 26px;
            }
            .box {
                display: block;
            }
            .img {
                position: relative;
                margin: 0 0 20px;
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            .p {
                line-height: 26px;
                height: auto;
            }
        }
        @media screen and (max-width: 750px) {
            &:before {
                top: 0;
                left: 50px;
                margin-left: 0;
            }
            .history-li {
                margin-bottom: 20px !important;
                &:nth-child(odd) {
                    .year {
                        order: 0;
                        padding-left: 0;
                        text-align: right;
                        &:before,
                        &:after {
                            right: auto;
                            left: 100%;
                        }
                    }
                    .box {
                        margin-right: 0;
                    }
                }
                &:nth-child(even),
                &:nth-child(odd) {
                    .year {
                        padding-right: 15px;
                        &:before {
                            margin-left: -10px;
                        }
                        &:after {
                            margin-left: -4px;
                        }
                    }
                    .box {
                        margin-left: 24px;
                    }
                }
            }
            .box {
                border-radius: 6px;
            }
            .year {
                width: 50px;
                font-size: 15px;

                &:after {
                    width: 8px;
                    height: 8px;
                }
            }
            .img {
                margin-bottom: 10px;
            }
            .p {
                line-height: 20px;
                font-size: 12px;
                height: auto;
            }
        }
    }

    .swiper-button-next1 {
        position: absolute;
        left: 50%;
        bottom: -24px;
        transform: translateX(-50%);
        cursor: pointer;
        @media screen and (max-width: 750px) {
            left: 50px;
            transform: translateX(35%);
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .swiper-button-prev1 {
        position: absolute;
        top: -51px;
        left: 50%;
        transform: translateX(-50%);
        @media screen and (max-width: 750px) {
            left: 50px;
            transform: translateX(-25%);
        }
        @media screen and (max-width: 500px) {
            top: -30px;
            transform: translateX(3%);
        }
        img {
            max-width: 100%;
            height: auto;
            @media screen and (max-width: 500px) {
                width: 30px;
            }
        }

    }
}