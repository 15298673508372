.project_list-container {
    padding: px2rem(75px) 0 px2rem(46px);
    overflow: hidden;
    background-color: #ebebeb;
    .pro-content {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        overflow: hidden;
        margin: 0 px2rem(-16px);
        .pro-box {
            width: 33.3%;
            box-sizing: border-box;
            padding: 0 px2rem(16px);
            padding-bottom: px2rem(40px);
            @media screen and (max-width: 750px) {
                width: 50%;
            }
            @media screen and (max-width: 500px) {
                padding-bottom: px2rem(30px);
            }
            .img {
                position: relative;
                width: 100%;
                box-sizing: border-box;
                padding-bottom: (294 / 478) * 100%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: all .3s;
                    max-width: 100%;
                    height: auto;
                }
            }
            .img-name {
                font-size: 18px;
                line-height: 56px;
                height: 56px;
                overflow: hidden;
                color: #333;
                text-align: center;
                @media screen and (max-width: 500px) {
                    font-size: 15px;
                    line-height: 30px;
                    height: 30px;
                }
            }
        }
        .pro-box:hover {
            .img {
                img {
                    transform: translate(-50%, -50%) scale(1.1);
                }
            }
            .img-name {
                color: $baseColor;
            }
        }
    }
}