.banner-container {
    .swiper-slide {
        .img {
            position: relative;
            display: block;
            width: 100%;
            padding-bottom: (850/1920) *100%;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .swiper-pagination {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        z-index: 2;
        bottom: auto;
        @media screen and (max-width: 750px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            top: auto;
            transform: none;
            bottom: px2rem(50px);
        }
        .swiper-pagination-bullet {
            margin: px2rem(6px) px2rem(99px) px2rem(6px) 0;
            opacity: 1;
            border: 2px solid #fff;
            outline: none;
            background-color: rgba(255, 255, 255, 0);
            transition: all .3s;
            width: 15px;
            height: 15px;
            box-sizing: border-box;
            @media screen and (max-width: 750px) {
                margin: 0 px2rem(20px);
            }
            &-active {
                //background: url(../images/whitebit.png);
                //background-repeat: no-repeat;
                //background-position: center;
                position: relative;
                outline: none;
                //border: 2px solid rgba(255, 255, 255, 0);
                //width: 60px !important;
                //height: 20px !important;
                width: 8px;
                height: 8px;
                border: none;
                background-color: $baseColor;
                opacity: 1;
                margin: px2rem(6px) 103px px2rem(96px) 0;
                border-radius: 50%;
                @media screen and (max-width: 750px) {
                    margin: 0 px2rem(20px);
                }
            }
            &-active:before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                content: '';
                width: 19px;
                height: 19px;
                border-radius: 50%;
                border: 2px solid $baseColor;
            }
            &-active:after {
                position: absolute;
                top: 30px;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                content: '';
                width: 1px;
                height: 72px;
                background-color: #fff;
                @media screen and (max-width: 750px) {
                    content: none;
                }
            }
        }
    }
}
.banner-container-mb {
    display: none;
    @media screen and (max-width: 750px) {
        display: block;
    }
    .swiper-slide {
        .img {
            padding-bottom: (882/750) *100%;
        }
    }
}
.banner-container-pc {
    @media screen and (max-width: 750px) {
        display: none;
    }
}
.index-cpzx {
    margin-top: 86px;
    @media screen and (max-width: 750px) {
        margin-top: px2rem(90px);
        padding: 0 px2rem(28px);
    }
    .cpzx-nav {
        display: flex;
        justify-content: space-between;
        padding: 0px 230px;
        box-sizing: border-box;
        @media screen and (max-width: 1680px) {
            padding: 0 100px;
        }
        @media screen and (max-width: 1260px) {
            padding: 0 50px;
        }
        @media screen and (max-width: 750px) {
            flex-direction: column;
            align-items: center;
            padding: 0px;
        }
        .cpzx-xl {
            display: flex;
            @media screen and (max-width: 750px) {
                margin-top: px2rem(40px);
            }
            .xl-box {
                display: flex;
                align-items: flex-start;
                position: relative;
                margin-left: 96px;
                cursor: pointer;
                @media screen and (max-width: 1260px) {
                    margin-left: 40px;
                }
                @media screen and (max-width: 750px) {
                    margin-left: 0px;
                    padding-right: px2rem(56px);
                }
                .icon {
                    font-size: px2rem(30px);
                }
                .name {
                    font-size: 16px;
                    line-height: 30px;
                    margin-left: 5px;
                    @media screen and (max-width: 750px) {
                        font-size: px2rem(30px);
                        line-height: px2rem(30px);
                    }
                }
            }
            .xl-box:before {
                content: '/';
                position: absolute;
                top: 15px;
                right: -50px;
                transform: translateY(-50%);
                color: #cdcdcd;
                font-size: 14px;
                line-height: 30px;
                @media screen and (max-width: 750px) {
                    content: none;
                }
            }
            .xl-box:nth-last-of-type(1) {
                @media screen and (max-width: 750px) {
                    padding-right: 0;
                }
            }
            .xl-box:nth-last-of-type(1):before {
                content: '';
            }
            .on {
                .icon {
                    color: $baseColor;
                }
            }
        }
    }
    .cpzx-lunbo {
        position: relative;
        margin-top: px2rem(24px);
        @media screen and (max-width: 750px) {
            margin-top: px2rem(38px);
        }
        .cpzx-container {
            .swiper-slide {
                width: (1453 / 1920) * 100%;
                height: 100%;
                position: relative;
                overflow: hidden;
                @media screen and (max-width: 750px) {
                    width: 100%;
                }
                .img-box {
                    display: block;
                    position: relative;
                    width: 100%;
                    padding-bottom: (664 / 1453) * 100%;
                    background-position: center center !important;
                    background-repeat: no-repeat !important;
                    background-size: cover !important;
                    @media screen and (max-width: 750px) {
                        padding-bottom: 0;
                        background: none !important;
                    }
                    .dsc {
                        display: none;
                        position: absolute;
                        bottom: 0px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: (854 / 1453) * 100%;
                        background-color: #fff;
                        padding: px2rem(32px) px2rem(105px) 0px;
                        box-sizing: border-box;

                        //animation-name: b2t;
                        //-webkit-animation-name: b2t;
                        //-ms-animation-name: b2t;
                        //animation-timing-function: linear;
                        //-webkit-animation-timing-function: linear;
                        //-ms-animation-timing-function: linear;
                        //animation-fill-mode: forwards;
                        //-webkit-animation-fill-mode: forwards;
                        //-ms-animation-fill-mode: forwards;
                        //animation-duration: .5s;
                        //-webkit-animation-duration: .5s;
                        //-ms-animation-duration: .5s;

                        @media screen and (max-width: 1680px) {
                            width: 80%;
                            padding: px2rem(32px) px2rem(50px) 0px;
                        }
                        @media screen and (max-width: 1260px) {
                            width: 90%;
                            padding: px2rem(26px) px2rem(20px) 0px;
                        }
                        @media screen and (max-width: 750px) {
                            display: block;
                            padding: 0;
                            position: static;
                            width: 100%;
                            transform: none;
                        }
                        .mb-img {
                            width: 100%;
                            display: none;
                            @media screen and (max-width: 750px) {
                                display: block;
                            }
                            .img {
                                position: relative;
                                width: 100%;
                                padding-bottom: (664 / 1453) * 100%;
                                img {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    max-width: 100%;
                                    height: auto;
                                }
                            }
                        }
                        .name {
                            @media screen and (max-width: 750px) {
                                display: none;
                            }
                            .cn {
                                font-size: 24px;
                                line-height: 24px;
                                @media screen and (max-width: 1260px) {
                                    font-size: 16px;
                                    line-height: 16px;
                                }
                            }
                            .en {
                                font-size: 13px;
                                line-height: 18px;
                                color: #999;
                                text-transform: uppercase;
                                @media screen and (max-width: 1260px) {
                                    line-height: 16px;
                                }
                            }
                        }
                        .word {
                            margin-left: px2rem(25px);
                            @media screen and (max-width: 1260px) {
                                margin-left: 10px;
                            }
                            @media screen and (max-width: 750px) {
                                margin-left: 0px;
                                margin-top: px2rem(20px);
                            }
                            span {
                                display: inline-block;
                                padding-left: px2rem(22px);
                                font-size: 14px;
                                line-height: 30px;
                                color: #666;
                                border-left: 1px solid #ccc;
                                @media screen and (max-width: 1260px) {
                                    line-height: 20px;
                                }
                                @media screen and (max-width: 750px) {
                                    border-left: none;
                                    padding: 0px px2rem(90px);
                                    font-size: px2rem(26px);
                                    line-height: px2rem(36px);
                                    color: #666;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
                .img-box:before {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, .5);
                    content: '';
                }
            }
            .swiper-slide-active {
                //width: calc(75.6% - 36px);
                .img-box {
                    .dsc {
                        display: flex;
                        @media screen and (max-width: 750px) {
                            display: block;
                        }
                    }
                }
                .img-box:before {
                    content: none;
                }
            }
        }
        .swiper-button-next1 {
            right: (155 / 1920) * 100%;
            @media screen and (max-width: 750px) {
                right: px2rem(-28px);
            }
        }
        .swiper-button-prev1 {
            left: (157 / 1920) * 100%;
            @media screen and (max-width: 750px) {
                left: px2rem(-28px);
            }
        }
        .swiper-button-next1,
        .swiper-button-prev1 {
            width: 57px;
            height: 57px;
            background: linear-gradient(#2f82ac, #51a5b1); /*标准语法，必须放在最后*/
            outline: none;
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            cursor: pointer;
            @media screen and (max-width: 1260px) {
                width: 40px;
                height: 40px;
            }
            @media screen and (max-width: 750px) {
                width: px2rem(60px);
                height: px2rem(60px);
                top: calc(50% - 52px);
            }
            @media screen and (max-width: 500px) {
                top: calc(50% - 24px);
            }
        }
        .swiper-button-disabled {
            opacity: 1;
            cursor: auto;
            //pointer-events: none
        }
        .swiper-button-prev1:before,
        .swiper-button-next1:before {
            position: absolute;
            font-size: 20px;
            color: #fff;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media screen and (max-width: 1000px) {
                font-size: px2rem(26px);
            }
        }
        .swiper-button-prev1:before {
            transform: translate(-50%, -50%) rotateY(180deg);
        }
        .swiper-button-next1:hover,
        .swiper-button-prev1:hover {
            background: linear-gradient(#0b80a8, #028695);
        }
        .swiper-button-next1:hover:before,
        .swiper-button-prev1:hover:before {
            color: #fff;
        }
    }
}
.index-spbf {
    background-size: cover !important;
    height: 920px;
    width: 100%;
    margin-top: 155px;
    cursor: pointer;
    @media screen and (max-width: 1600px) {
        height: 700px;
    }
    @media screen and (max-width: 1400px) {
        height: 600px;
    }
    @media screen and (max-width: 750px) {
        margin-top: px2rem(85px);
        height: auto;
    }
    .img-mb {
        display: none;
        width: 100%;
        @media screen and (max-width: 750px) {
            display: block;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .spbf-content {
        display: flex;
        align-items: flex-end;
        padding-left: 152px;
        height: 100%;
        @media screen and (max-width: 1600px) {
            padding-left: 80px;
        }
        @media screen and (max-width: 1260px) {
            padding-left: 50px;
        }
        @media screen and (max-width: 750px) {
            display: none;
        }
        .btn-container {
            display: flex;
            align-items: flex-end;
            width: (692 / 1760) * 100%;
            .btn-box {
                width: 33.3%;
                height: 210px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
                @media screen and (max-width: 1400px) {
                    height: 150px;
                }
                @media screen and (max-width: 1260px) {
                    height: 100px;
                }
                .icon {
                    font-size: 48px;
                    color: #fff;
                    transition: all .3s;
                    @media screen and (max-width: 1260px) {
                        font-size: 30px;
                    }
                }
                .name {
                    margin-top: 23px;
                    font-size: 16px;
                    line-height: 16px;
                    color: #fff;
                }
            }
            .btn-box:nth-of-type(1) {
                background: linear-gradient(#1e1e1e, #1e1e1e);
            }
            .btn-box:nth-of-type(2) {
                background: linear-gradient(#343434, #343434);
            }
            .btn-box:nth-of-type(3) {
                background: linear-gradient(#2f82ac, #51a5b1);
            }
            .btn-box:hover {
                background: linear-gradient(#0b80a8, #028695);
                .icon {
                    transform: rotateY(180deg);
                }
            }
        }
        .spbf-dsc {
            flex: 1;
            display: flex;
            flex-direction: column;
            width: (1068 / 1760) * 100%;
            box-sizing: border-box;
            padding: 36px 160px 55px 110px;
            background-color: #fff;
            cursor: pointer;
            @media screen and (max-width: 1260px) {
                padding: 36px 100px 25px 50px;
            }
            @media screen and (max-width: 1000px) {
                padding: 20px 50px 25px 20px;
            }
            .name {
                position: relative;
                font-size: 26px;
                line-height: 38px;
                color: #333;
            }
            .name:before {
                position: absolute;
                bottom: -7px;
                left: 0px;
                content: '';
                width: 68px;
                height: 2px;
                background: linear-gradient(to right, #2f82ac, #51a5b1);
            }
            .word {
                margin-top: 15px;
                font-size: 14px;
                line-height: 30px;
                color: #888;
                height: 120px;
                overflow: hidden;
            }
            .more {
                display: block;
                margin-top: 23px;
                span {
                    display: inline-block;
                    font-size: 12px;
                    line-height: 22px;
                    font-weight: bold;
                    color: #888;
                    text-transform: uppercase;
                }
                img {
                    margin-left: px2rem(26px);
                    vertical-align: baseline !important;
                    max-width: 100%;
                    height: auto;
                }
            }
        }
        .spbf-dsc:hover {
            .name {
                color: $baseColor;
            }
            .more {
                span {
                    color: $baseColor;
                }
            }
        }
    }
}
.index-spbf-mb {
    position: relative;
    display: none;
    padding: 0 px2rem(30px) px2rem(78px);
    margin-top: -39px;
    @media screen and (max-width: 750px) {
        display: block;
    }
    .spbf-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: #fff;
        box-sizing: border-box;
        padding: px2rem(45px) px2rem(22px) 0px;
        .btn-container {
            order: 2;
            margin-top: px2rem(40px);
            display: flex;
            .btn-box {
                width: 33.3%;
                height: px2rem(210px);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
                .icon {
                    font-size: px2rem(50px);
                    color: #fff;
                    transition: all .3s;
                }
                .name {
                    margin-top: px2rem(25px);
                    font-size: px2rem(26px);
                    line-height: px2rem(30px);
                    color: #fff;
                }
            }
            .btn-box:nth-of-type(1) {
                background: linear-gradient(#1e1e1e, #1e1e1e);
            }
            .btn-box:nth-of-type(2) {
                background: linear-gradient(#343434, #343434);
            }
            .btn-box:nth-of-type(3) {
                background: linear-gradient(#2f82ac, #51a5b1);
            }
            .btn-box:hover {
                background: linear-gradient(#0b80a8, #028695);
                .icon {
                    transform: rotateY(180deg);
                }
            }
        }
        .spbf-dsc {
            order: 1;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            background-color: #fff;
            cursor: pointer;
            .name {
                position: relative;
                font-size: px2rem(40px);
                line-height: px2rem(40px);
                color: #333;
                text-align: center;
            }
            .word {
                margin-top: px2rem(22px);
                font-size: px2rem(26px);
                line-height: px2rem(42px);
                color: #888;
            }
            .more {
                display: none;
                margin-top: 23px;
            }
        }
        .spbf-dsc:hover {
            .name {
                color: $baseColor;
            }
            .more {
                span {
                    color: $baseColor;
                }
            }
        }
    }
}
.index-video-dialog {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(#000, .5);
    top: 0;
    left: 0;
    z-index: 104;
    display: none;
    .box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1080px;
        height: 720px;
        background-color: #000;
        max-width: 100%;
        max-height: 100%;
        //overflow: hidden;
    }
    .video,
    video {
        display: block;
        width: 100%;
        height: 100%;
        outline: none;
        overflow: hidden;
    }
    .index-video-close {
        position: absolute;
        color: #fff;
        font-size: 32px;
        right: -10px;
        top: -10px;
        z-index: 5;
        cursor: pointer;
        border-radius: 100%;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
    }
    @media (max-width: 1024px) {
        background-color: #000;
        .box {
            box-sizing: border-box;
            padding: 30px 0;
        }
        .index-video-close {
            top: 0;
            right: 5px;
        }
    }
}
.index-title {
    .cn {
        font-size: 30px;
        line-height: 30px;
        color: #333;
        @media screen and (max-width: 750px) {
            font-size: px2rem(40px);
            line-height: px2rem(40px);
        }
        @media screen and (max-width: 500px) {
            font-size: 25px;
            line-height: 25px;
        }
    }
    .en {
        font-size: 14px;
        line-height: 20px;
        font-family: Arial;
        color: #a6a6a6;
        text-transform: uppercase;
        @media screen and (max-width: 750px) {
            display: none;
        }
    }
}
.index-xwzx {
    padding-top: px2rem(100px);
    padding-bottom: px2rem(170px);
    background-color: #ebebeb;
    @media screen and (max-width: 750px) {
        padding-top: px2rem(70px);
        padding-bottom: px2rem(78px);
    }
    .xwzx-top {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 750px) {
            justify-content: center;
        }
        .more {
            display: flex;
            align-items: flex-end;
            @media screen and (max-width: 750px) {
                display: none;
            }
            span {
                font-size: 14px;
                line-height: 30px;
                font-family: Arial;
                color: #a6a6a6;
            }
        }
    }
    .xwzx-content {
        margin-top: 36px;
        display: flex;
        @media screen and (max-width: 750px) {
            margin-top: px2rem(30px);
            display: block;
        }
        .left {
            width: (700 / 1600) * 100%;
            margin-right: 75px;
            @media screen and (max-width: 750px) {
                width: 100%;
                margin-right: 0px;
            }
            .left-box {
                width: 100%;
                display: flex;
                background-color: #fff;
                margin-bottom: px2rem(35px);
                @media screen and (max-width: 750px) {
                    margin-bottom: px2rem(18px);
                }
                .word-cotent {
                    width: (514 / 700) * 100%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    box-sizing: border-box;
                    padding: 0 45px 0 50px;
                    @media screen and (max-width: 1600px) {
                        padding: 20px 40px;
                    }
                    @media screen and (max-width: 1400px) {
                        padding: 10px 30px;
                    }
                    @media screen and (max-width: 750px) {
                        order: 2;
                        flex: 1;
                    }
                    .title {
                        font-size: 20px;
                        line-height: 24px;
                        color: #000;
                        height: 24px;
                        overflow: hidden;
                        @media screen and (max-width: 1400px) {
                            font-size: 16px;
                            line-height: 20px;
                            height: 20px;
                        }
                        @media screen and (max-width: 750px) {
                            font-size: px2rem(26px);
                            line-height: px2rem(26px);
                            height: px2rem(26px);
                        }
                    }
                    .dsc {
                        margin-top: 15px;
                        font-size: 14px;
                        line-height: 24px;
                        height: 48px;
                        overflow: hidden;
                        color: #888;
                        @media screen and (max-width: 1400px) {
                            font-size: 13px;
                            line-height: 18px;
                            height: 36px;
                            margin-top: 10px;
                        }
                        @media screen and (max-width: 1180px) {
                            height: 18px;
                        }
                        @media screen and (max-width: 750px) {
                            margin-top: px2rem(15px);
                            font-size: px2rem(26px);
                            line-height: px2rem(36px);
                            height: px2rem(72px);
                        }
                    }
                    .time {
                        margin-top: px2rem(12px);
                        font-size: px2rem(16px);
                        line-height: px2rem(16px);
                        color: #4fafc9;
                    }
                }
                .img-content {
                    flex: 1;
                    width: px2rem(186px);
                    @media screen and (max-width: 750px) {
                        order: 1;
                        flex: none;
                    }
                    .img-box {
                        width: 100%;
                        position: relative;
                        padding-bottom: (185 / 186) * 100%;
                        overflow: hidden;
                    }
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        height: auto;
                        transition: all .3s;
                    }
                }
            }
            .left-box:nth-last-of-type(1) {
                margin-bottom: 0px;
            }
            .left-box:hover {
                .title {
                    color: $baseColor;
                }
                .img-box {
                    img {
                        transform: translate(-50%, -50%) scale(1.1);
                    }
                }
            }
        }
        .right {
            flex: 1;
            width: auto;
            @media screen and (max-width: 750px) {
                margin-top: px2rem(35px);
                display: block;
            }
            @media screen and (max-width: 500px) {
                margin-top: 20px;
            }
            .info-box {
                display: flex;
                justify-content: space-between;
                padding-bottom: 27px;
                margin-bottom: 30px;
                box-sizing: border-box;
                border-bottom: 1px dashed #bebebe;
                @media screen and (max-width: 1600px) {
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                }
                @media screen and (max-width: 750px) {
                    display: block;
                }
                .info-left {
                    margin-right: 80px;
                    @media screen and (max-width: 1600px) {
                        margin-right: 50px;
                    }
                    @media screen and (max-width: 750px) {
                        margin-right: 0px;
                    }
                    .title {
                        position: relative;
                        font-size: 20px;
                        line-height: 24px;
                        height: 24px;
                        padding-left: 20px;
                        overflow: hidden;
                        @media screen and (max-width: 1400px) {
                            margin-right: 50px;
                            font-size: 16px;
                            line-height: 20px;
                            height: 20px;
                        }
                        @media screen and (max-width: 750px) {
                            font-size: px2rem(26px);
                            line-height: px2rem(26px);
                            height: px2rem(26px);
                        }
                    }
                    .title:before {
                        position: absolute;
                        top: 50%;
                        left: 0px;
                        transform: translateY(-50%);
                        width: 12px;
                        height: 12px;
                        background: url(../images/bit.png) center center;
                        background-size: cover;
                        content: '';
                    }
                    .dsc {
                        margin-top: 18px;
                        font-size: 14px;
                        line-height: 24px;
                        height: 48px;
                        overflow: hidden;
                        @media screen and (max-width: 1400px) {
                            font-size: 13px;
                            line-height: 18px;
                            height: 36px;
                            margin-top: 10px;
                        }
                        @media screen and (max-width: 1180px) {
                            height: 18px;
                        }
                        @media screen and (max-width: 750px) {
                            font-size: px2rem(26px);
                            line-height: px2rem(36px);
                            height: px2rem(72px);
                            color: #888;
                        }
                    }
                }
                .info-right {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    @media screen and (max-width: 750px) {
                        flex-direction: row;
                        justify-content: left;
                    }
                    .r {
                        text-align: center;
                        font-size: 48px;
                        line-height: 35px;
                        @media screen and (max-width: 1400px) {
                            font-size: 30px;
                            line-height: 25px;
                        }
                        @media screen and (max-width: 750px) {
                            order: 2;
                            display: none;
                        }
                    }
                    .ny {
                        font-size: 16px;
                        line-height: 24px;
                        @media screen and (max-width: 1400px) {
                            font-size: 12px;
                            line-height: 18px;
                        }
                        @media screen and (max-width: 750px) {
                            order: 1;
                            display: none;
                        }
                    }
                    .mb-time {
                        margin-top: 10px;
                        font-size: px2rem(16px);
                        line-height: px2rem(16px);
                        color: #4fafc9;
                        display: none;
                        @media screen and (max-width: 750px) {
                            display: block;
                        }
                    }
                }
            }
            .info-box:nth-last-of-type(1) {
                margin-bottom: 0px;
                border: none;
            }
            .info-box:hover {
                .title {
                    color: $baseColor;
                }
                .info-right {
                    .r,
                    .ny {
                        color: $baseColor;
                    }
                }
            }
        }
    }
}
.index-produce {
    display: flex;
    overflow: hidden;
    .produce-box {
        display: block;
        width: (534 / 1920) * 100%;
        transition: all .9s;
        height: 654px;
        cursor: pointer;
        @media screen and (max-width: 750px) {
            height: 270px;
        }
        @media screen and (max-width: 500px) {
            height: 150px;
        }
        .active {
            display: none;
            position: relative;
            height: 100%;
            width: 100%;
            transition: all .9s;
            background-size: cover;

            .img-bk {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 9;
                width: 100%;
                height: 100%;
                opacity: .6;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .dsc {
                position: absolute;
                left: 79px;
                bottom: 55px;
                display: flex;
                justify-content: center;
                z-index: 99;
                width: 100%;
                @media screen and (max-width: 1400px) {
                    left: 30px;
                    bottom: 30px;
                }
                @media screen and (max-width: 750px) {
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: px2rem(15px);
                }
                .icon {
                    position: relative;
                    display: block;
                    width: 58px;
                    height: 58px;
                    background: linear-gradient(#2f82ac, #51a5b1);
                    @media screen and (max-width: 750px) {
                        width: px2rem(60px);
                        height: px2rem(60px);
                    }
                }
                .icon:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: px2rem(30px);
                    color: #fff;
                    @media screen and (max-width: 500px) {
                        font-size: 20px;
                    }
                }
                .r-box {

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 15px;
                    .name {
                        font-size: 24px;
                        line-height: 32px;
                        color: #fff;
                        @media screen and (max-width: 750px) {
                            white-space: nowrap;
                            font-size: 24px;
                            line-height: 24px;
                        }
                        @media screen and (max-width: 750px) {
                            font-size: 16px;
                            line-height: 16px;
                        }
                    }
                    .sm {
                        font-size: 14px;
                        line-height: 24px;
                        color: rgba(255, 255, 255, .4);
                        @media screen and (max-width: 750px) {
                            display: none;
                        }
                    }
                }
            }
        }
        .static {
            width: 100%;
            height: 100%;
            background-position: center center !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: all .9s;
            @media screen and (max-width: 750px) {
                background: url(../images/staticbk2.png) !important;
                background-position: center center !important;
                background-size: cover !important;
                background-repeat: no-repeat !important;
            }
            .icon {
                font-size: 60px;
                color: #666;
                margin-top: 220px;
                @media screen and (max-width: 750px) {
                    margin-top: 60px;
                }
                @media screen and (max-width: 500px) {
                    margin-top: 34px;
                    font-size: 30px;
                }
            }
            .dsc-box {
                margin-top: 115px;
                @media screen and (max-width: 750px) {
                    margin-top: 40px;
                }
                @media screen and (max-width: 500px) {
                    margin-top: 30px;
                }
                .name {
                    text-align: center;
                    font-size: 24px;
                    line-height: 24px;
                    color: #444;
                    @media screen and (max-width: 750px) {
                        font-size: 26px;
                        line-height: 26px;
                    }
                    @media screen and (max-width: 500px) {
                        font-size: 16px;
                        line-height: 18px;
                    }
                }
                .cn {
                    font-size: 16px;
                    line-height: 24px;
                    color: #a2a2a2;
                    font-family: Arial;
                    text-align: center;
                    @media screen and (max-width: 500px) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
    .produce-box:nth-of-type(2) {
        .static {
            @media screen and (max-width: 750px) {
                background: url(../images/staticbk.png) !important;
                background-position: center center !important;
                background-size: cover !important;
                background-repeat: no-repeat !important;
            }
        }
    }
    .on {
        width: (852 / 1920) * 100%;
        .active {
            display: block;
        }
        .static {
            display: none;
        }
    }
}


@keyframes b2t {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes b2t {
    0% {
        -webkit-transform: translateY(20px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}

@-ms-keyframes b2t {
    0% {
        -ms-transform: translateY(20px);
        opacity: 0;
    }
    100% {
        -ms-transform: translateY(0);
        opacity: 1;
    }
}