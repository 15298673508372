.franchise_scqj {
    background-color: #ebebeb;
    padding-top: px2rem(80px);
    .num-content {
        display: flex;
        justify-content: center;
        margin-top: px2rem(44px);
        .num-box {
            display: flex;

            .box {
                width: 276px;
                @media screen and (max-width: 1000px) {
                    width: 200px;
                }
                @media screen and (max-width: 750px) {
                    width: 180px;
                }
                @media screen and (max-width: 500px) {
                    width: 150px;
                }

                @media screen and (max-width: 450px) {
                    width: 120px;
                }
                @media screen and (max-width: 380px) {
                    width: 100px;
                }
                .sz {
                    font-size: 72px;
                    line-height: 55px;
                    font-weight: bold;
                    font-family: Arial;
                    color: #3c98b8;
                    border-right: 1px solid #d3d3d3;
                    box-sizing: border-box;
                    text-align: center;
                    @media screen and (max-width: 1500px) {
                        font-size: 50px;
                        line-height: 40px;
                    }
                    @media screen and (max-width: 750px) {
                        font-size: 28px;
                        line-height: 28px;
                    }

                    @media screen and (max-width: 380px) {
                        font-size: 20px;
                        line-height: 20px;
                    }

                }
                .letsp {
                    span {
                        display: inline-block;
                        height: 55px;
                        line-height: 55px;
                        @media screen and (max-width: 1500px) {
                            height: 40px;
                            line-height: 40px;
                        }
                        @media screen and (max-width: 750px) {
                            height: 28px;
                            line-height: 28px;
                        }
                        @media screen and (max-width: 380px) {
                            height: 20px;
                            line-height: 20px;
                        }
                    }
                    .jh {
                        position: relative;
                        margin-right: 10px;
                    }
                    .bfh {
                        font-size: 60px;
                        line-height: 72px;
                        @media screen and (max-width: 1500px) {
                            font-size: 40px;
                            line-height: 40px;
                        }
                        @media screen and (max-width: 750px) {
                            font-size: 24px;
                            line-height: 28px;
                        }
                        @media screen and (max-width: 380px) {
                            font-size: 20px;
                            line-height: 20px;
                        }
                    }
                    .jh:before {
                        position: absolute;
                        top: 50%;
                        right: px2rem(-12px);
                        transform: translateY(-50%);
                        font-size: px2rem(24px);
                        line-height: px2rem(24px);
                        font-family: Arial;
                        font-weight: bold;
                        color: #3c98b8;
                        content: '+';
                        @media screen and (max-width: 380px) {
                            right: -8px;
                        }
                    }
                }
                .wz {
                    text-align: center;
                    font-size: 18px;
                    line-height: 30px;
                    color: #777;
                    @media screen and (max-width: 1500px) {
                        font-size: 16px;
                        line-height: 26px;
                    }
                    @media screen and (max-width: 750px) {
                        font-size: 14px;
                        line-height: 22px;
                    }
                    @media screen and (max-width: 380px) {
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
            }

            .box:nth-last-of-type(1) {
                .sz {
                    border: none;

                }
            }
        }
    }
    .scqj-content {
        margin-top: 55px;
        @media screen and (max-width: 750px) {
            margin-top: px2rem(100px);
        }
        .img-content {
            margin-top: px2rem(30px);
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
            padding: 0 px2rem(30px);
            @media screen and (max-width: 750px) {
                padding: 0px;
            }
            .img-box {
                flex: 1;
                margin-right: px2rem(24px);
                box-sizing: border-box;
                //@media screen and (max-width: 750px) {
                //    flex: none;
                //    width: 100%;
                //    margin-right: 0px;
                //    margin-bottom: px2rem(24px);
                //}
                .img {
                    position: relative;
                    width: 100%;
                    padding-bottom: (464 / 463) *100%;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
            .img-box:nth-last-of-type(1) {
                margin-right: 0px;
                //@media screen and (max-width: 750px) {
                //    margin-bottom: 0px;
                //}
            }
        }
    }
}
.franchise_dpp {
    background-color: #ebebeb;
    padding-top: 102px;
    padding-bottom: 96px;
    @media screen and (max-width: 750px) {
        padding-top: px2rem(100px);
        padding-bottom: px2rem(100px);
    }
    .franchise_name {
        font-size: 30px;
        line-height: 30px;
        color: #333;
        font-weight: bold;
        text-align: center;
    }
    .franchise_name2 {
        margin-top: 8px;
        font-size: 18px;
        line-height: 18px;
        color: #888;
        text-align: center;
    }
    .dpp-content {
        position: relative;
        margin-top: 26px;
        padding: 0 134px;
        box-sizing: border-box;
        @media screen and (max-width: 1500px) {
            padding: 0 100px;
        }
        @media screen and (max-width: 750px) {
            padding: 0 px2rem(80px);
        }
        .dpp-lunbo {
            .swiper-slide {
                .img {
                    width: 100%;
                    padding: 53px 50px;
                    box-sizing: border-box;
                    background-color: #fff;
                    @media screen and (max-width: 1500px) {
                        padding: px2rem(30px);
                    }
                    .img-box {
                        position: relative;
                        width: 100%;
                        padding-bottom: (373 / 287) * 100%;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }
                .name {
                    margin-top: px2rem(26px);
                    font-size: px2rem(26px);
                    line-height: px2rem(26px);
                    height: px2rem(26px);
                    overflow: hidden;
                    text-align: center;
                    color: #333;
                }
            }
        }
        .swiper-button-next1 {
            right: 0px;
        }
        .swiper-button-prev1 {
            left: 0px;
        }
        .swiper-button-next1,
        .swiper-button-prev1 {
            width: 57px;
            height: 57px;
            background: linear-gradient(#2f82ac, #51a5b1); /*标准语法，必须放在最后*/
            outline: none;
            box-sizing: border-box;
            position: absolute;
            top: 45%;
            transform: translateY(-50%);
            z-index: 10;
            cursor: pointer;
            @media screen and (max-width: 1260px) {
                width: 40px;
                height: 40px;
            }
            @media screen and (max-width: 750px) {
                width: 40px;
                height: 40px;
                top: calc(50% - 30px);
            }
            @media screen and (max-width: 500px) {
                width: 30px;
                height: 30px;
                top: calc(50% - 13px);
            }
        }
        .swiper-button-disabled {
            opacity: 1;
            cursor: auto;
            //pointer-events: none
        }
        .swiper-button-prev1:before,
        .swiper-button-next1:before {
            position: absolute;
            font-size: 20px;
            color: #fff;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media screen and (max-width: 1000px) {
                font-size: px2rem(26px);
            }
        }
        .swiper-button-prev1:before {
            transform: translate(-50%, -50%) rotateY(180deg);
        }
        .swiper-button-next1:hover,
        .swiper-button-prev1:hover {
            background: linear-gradient(#0b80a8, #028695);
        }
        .swiper-button-next1:hover:before,
        .swiper-button-prev1:hover:before {
            color: #fff;
        }
    }
}
.franchise_bk {
    background-size: cover !important;
    height: px2rem(566px);
    width: 100%;
    position: relative;
    z-index: 2;
}
.franchise_gg {
    position: relative;
    background-color: #ebebeb;
    padding-bottom: 90px;
    @media screen and (max-width: 750px) {
        padding-bottom: px2rem(100px);
    }
    .wp1500 {
        position: relative;
        margin-top: px2rem(-439px);
        z-index: 9;
        .franchise_name {
            color: #fff;
        }
        .franchise_name2 {
            font-size: 22px;
            line-height: 22px;
            color: #fff;
        }
        .gg-cotent {
            position: relative;
            padding: 80px 90px 90px;
            box-sizing: border-box;
            background-color: #fff;
            margin-top: px2rem(63px);
            @media screen and (max-width: 1500px) {
                padding: 80px;
            }
            @media screen and (max-width: 750px) {
                padding: 50px;
            }
            @media screen and (max-width: 500px) {
                padding: 40px;
            }
            &:after {
                position: absolute;
                display: block;
                width: 100%;
                height: 95px;
                background: url(../images/shadow_1.png) center bottom no-repeat;
                background-size: 100% auto;
                left: 0;
                bottom: -55px;
                z-index: -1;
                content: '';
                opacity: .2;
                @media screen and (max-width: 750px) {
                    content: none;
                }
            }
            .swiper-slide {
                .img {
                    width: 100%;
                    position: relative;
                    padding-bottom: (263 / 321) * 100%;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
            .swiper-button-next1 {
                right: 25px;
                @media screen and (max-width: 1500px) {
                    right: px2rem(10px);
                }
            }
            .swiper-button-prev1 {
                left: 25px;
                @media screen and (max-width: 1500px) {
                    left: px2rem(10px);
                }
            }
            .swiper-button-next1,
            .swiper-button-prev1 {
                width: 57px;
                height: 57px;
                background: linear-gradient(#2f82ac, #51a5b1); /*标准语法，必须放在最后*/
                outline: none;
                box-sizing: border-box;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;
                cursor: pointer;
                @media screen and (max-width: 1260px) {
                    width: 40px;
                    height: 40px;
                }
                @media screen and (max-width: 750px) {
                    width: 40px;
                    height: 40px;
                }
                @media screen and (max-width: 500px) {
                    width: 30px;
                    height: 30px;
                }
            }
            .swiper-button-disabled {
                opacity: 1;
                cursor: auto;
                //pointer-events: none
            }
            .swiper-button-prev1:before,
            .swiper-button-next1:before {
                position: absolute;
                font-size: 20px;
                color: #fff;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @media screen and (max-width: 1000px) {
                    font-size: px2rem(26px);
                }
            }
            .swiper-button-prev1:before {
                transform: translate(-50%, -50%) rotateY(180deg);
            }
            .swiper-button-next1:hover,
            .swiper-button-prev1:hover {
                background: linear-gradient(#0b80a8, #028695);
            }
            .swiper-button-next1:hover:before,
            .swiper-button-prev1:hover:before {
                color: #fff;
            }
        }
    }
}
.franchise_xx {
    background-color: #ebebeb;
    .franchise_name2 {
        font-size: 22px;
        line-height: 22px;
        margin-top: 8px;
    }
    .xx-content {
        margin-top: px2rem(40px);
        padding: 0 73px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: 1000px) {
            padding: 0;
        }
        .left {
            flex: 1;
            margin-right: 15px;
            @media screen and (max-width: 750px) {
                flex: none;
                width: 100%;
                margin-right: 0px;
                margin-bottom: 15px;
            }
            .img {
                width: 100%;
                position: relative;
                padding-bottom: (438 / 669) * 100%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    height: auto;
                }
            }
        }
        .right {
            flex: 1;
            @media screen and (max-width: 750px) {
                flex: none;
                width: 100%;
            }
            .right-box {
                display: flex;
                flex-wrap: wrap;
                .img-box {
                    width: calc(50% - 7.5px);
                    margin-right: 15px;
                    margin-bottom: 13px;
                    .img {
                        width: 100%;
                        position: relative;
                        padding-bottom: (213 / 325) * 100%;
                        overflow: hidden;
                    }
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        height: auto;
                    }
                }
                .img-box:nth-of-type(2n) {
                    margin-right: 0px;
                }
                .img-box:nth-of-type(3),
                .img-box:nth-of-type(4) {
                    margin-bottom: 0px;
                }
            }
        }
    }
}
.franchise_bf {
    background-color: #ebebeb;
    padding-top: 85px;
    padding-bottom: 110px;
    box-sizing: border-box;
    @media screen and (max-width: 750px) {
        padding: px2rem(100px) 0;
    }
    .franchise_name2 {
        font-size: 22px;
        line-height: 22px;
        margin-top: 8px;
    }
    .bf-content {
        margin-top: px2rem(40px);
        box-sizing: border-box;
        padding: 0 73px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        @media screen and (max-width: 750px) {
            padding: 0;
        }
        .img-box {
            width: calc(33.3% - 12px);
            margin-right: px2rem(18px);
            box-sizing: border-box;
            //@media screen and (max-width: 750px) {
            //    width: 100%;
            //    margin-right: 0px;
            //    margin-bottom: 18px;
            //}
            @media screen and (max-width: 500px) {
                width: calc(33.3% - 13.5px);
            }
            .img {
                width: 100%;
                position: relative;
                padding-bottom: (591 / 436) * 100%;
            }
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                height: auto;
            }
        }
        .img-box:nth-last-of-type(1) {
            margin-right: 0px;
        }
    }
}
.franchise_gyl {
    background-color: #ebebeb;
    padding-bottom: 105px;
    @media screen and (max-width: 750px) {
        padding-bottom: px2rem(100px);
    }
    .franchise_name2 {
        font-size: 22px;
        line-height: 22px;
        margin-top: 8px;
    }
    .gyl-content {
        position: relative;
        margin-top: 33px;
        display: flex;
        flex-wrap: wrap;
        z-index: 2;
        @media screen and (max-width: 750px) {
            margin-top: px2rem(40px);
        }
        &:after {
            position: absolute;
            display: block;
            width: 100%;
            height: 95px;
            background: url(../images/shadow_1.png) center bottom no-repeat;
            background-size: 100% auto;
            left: 0;
            bottom: -55px;
            z-index: -1;
            content: '';
            opacity: .2;
            @media screen and (max-width: 750px) {
                content: none;
            }
        }
        .img-box {
            width: 25%;
            border-right: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
            background-color: #fff;
            height: 165px;
            padding: px2rem(20px);
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 750px) {
                width: 50%;
                height: 120px;
            }
            .img {
                width: 100%;
                position: relative;
                padding-bottom: (85 / 176) * 100%;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}
.franchise_jz {
    position: relative;
    background-color: #ebebeb;
    padding-bottom: 76px;
    @media screen and (max-width: 750px) {
        padding-bottom: px2rem(100px);
    }
    .wp1500 {
        position: relative;
        margin-top: px2rem(-440px);
        z-index: 9;
    }
    .franchise_name {
        color: #fff;
    }
    .franchise_name2 {
        color: #fff;
        font-size: 22px;
        line-height: 22px;
        margin-top: 15px;
    }
    .xx-content {
        margin-top: px2rem(63px);
        padding: 68px 73px;
        box-sizing: border-box;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: 750px) {
            padding: px2rem(40px) px2rem(30px);
        }
        &:after {
            position: absolute;
            display: block;
            width: 100%;
            height: 95px;
            background: url(../images/shadow_1.png) center bottom no-repeat;
            background-size: 100% auto;
            left: 0;
            bottom: -55px;
            z-index: -1;
            content: '';
            opacity: .2;
            @media screen and (max-width: 750px) {
                content: none;
            }
        }
        .left {
            flex: 1;
            margin-right: 8px;
            @media screen and (max-width: 750px) {
                flex: none;
                width: 100%;
                margin-right: 0px;
                margin-bottom: 8px;
            }
            .img {
                width: 100%;
                position: relative;
                padding-bottom: (484 / 668) * 100%;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    height: auto;
                }
            }
        }
        .right {
            flex: 1;
            @media screen and (max-width: 750px) {
                flex: none;
                width: 100%;

            }
            .right-box {
                display: flex;
                flex-wrap: wrap;
                .img-box {
                    width: calc(50% - 2.5px);
                    margin-right: 5px;
                    margin-bottom: 4px;
                    .img {
                        position: relative;
                        width: 100%;
                        padding-bottom: (240 / 330) * 100%;
                    }
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        height: auto;

                    }
                }
                .img-box:nth-of-type(2n) {
                    margin-right: 0px;
                }
                .img-box:nth-of-type(3),
                .img-box:nth-of-type(4) {
                    margin-bottom: 0px;
                }
            }
        }
    }
}
.franchise_zg {
    background-color: #ebebeb;
    padding-bottom: 96px;
    @media screen and (max-width: 750px) {
        padding-bottom: px2rem(100px);
    }
    .franchise_name2 {
        font-size: 22px;
        line-height: 22px;
    }
    .zg-content {
        box-sizing: border-box;
        margin-top: px2rem(39px);
        padding: 0 73px;
        @media screen and (max-width: 750px) {
            padding: 0;
        }
        .circle {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .circle-box {
                margin-right: 10px;

                @media screen and (max-width: 1500px) {
                    margin-bottom: 10px;
                }
                .y {
                    position: relative;
                    width: px2rem(116px);
                    height: px2rem(116px);
                    border-radius: 50%;
                    transition: all .3s;
                    background: linear-gradient(#0b80a8, #028695);
                }
                .y:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: px2rem(48px);
                    color: #fff;
                }
                .name {
                    margin-top: px2rem(13px);
                    font-size: px2rem(16px);
                    line-height: px2rem(16px);
                    color: #333;
                    text-align: center;
                }
            }
            .circle-box:hover {
                .name {
                    color: $baseColor;
                }
            }

        }
    }
}
.franchise_ms {
    background-color: #ebebeb;
    padding-bottom: 118px;
    @media screen and (max-width: 750px) {
        padding-bottom: px2rem(100px);
    }
    .hotline {
        font-size: 22px;
        line-height: 22px;
        margin-top: px2rem(22px);
        color: #3c98b8;
        text-align: center;
        @media screen and (max-width: 1500px) {
            font-size: 16px;
            line-height: 16px;
        }
    }
    .phone {
        font-size: 36px;
        line-height: 36px;
        font-weight: bold;
        color: #3c98b8;
        margin-top: px2rem(9px);
        text-align: center;
        @media screen and (max-width: 1500px) {
            font-size: 22px;
            line-height: 22px;
        }
    }
    .ms-content {
        padding: 26px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        margin: px2rem(-9px);
        @media screen and (max-width: 1500px) {
            padding: px2rem(26px) 0;
        }
        .img-box {
            width: 25%;
            padding: px2rem(9px);
            box-sizing: border-box;
            @media screen and (max-width: 750px) {
                width: 50%;
            }
            .img {
                width: 100%;
                position: relative;
                padding-bottom: (257 / 364) * 100%;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}
.franchise_name {
    font-size: 36px;
    line-height: 36px;
    color: #333;
    font-weight: bold;
    text-align: center;
    @media screen and (max-width: 1500px) {
        font-size: 28px !important;
        line-height: 28px !important;
    }

    @media screen and (max-width: 1500px) {
        font-size: 22px !important;
        line-height: 22px !important;
    }
}
.franchise_name2 {
    margin-top: 16px;
    font-size: 18px;
    line-height: 18px;
    color: #888;
    text-align: center;
    @media screen and (max-width: 1500px) {
        font-size: 16px !important;
        line-height: 16px !important;
        margin-top: 12px !important;
    }

    @media screen and (max-width: 750px) {
        font-size: 14px !important;
        line-height: 14px !important;
        margin-top: 10px !important;
    }
}



.opacity-0 {
    opacity: 0;
}

.anim .anim-right-to-left {
    animation-name: r2l;
    -webkit-animation-name: r2l;
    -ms-animation-name: r2l;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    -ms-animation-timing-function: ease;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    -ms-animation-duration: 1s;
}
.anim .anim-left-to-right {
    animation-name: l2r;
    -webkit-animation-name: l2r;
    -ms-animation-name: l2r;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    -ms-animation-timing-function: ease;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    -ms-animation-duration: 1s;
}
.anim .anim-bottom-to-top {
    animation-name: b2t;
    -webkit-animation-name: b2t;
    -ms-animation-name: b2t;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    -ms-animation-timing-function: ease;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    -ms-animation-duration: 1s;
}
.anim .anim-delay-2 {
    animation-delay: .2s;
    -webkit-animation-delay: .2s;
    -ms-animation-delay: .2s;
}
.anim .anim-delay-4 {
    animation-delay: .4s;
    -webkit-animation-delay: .4s;
    -ms-animation-delay: .4s;
}
.anim .anim-delay-6 {
    animation-delay: .6s;
    -webkit-animation-delay: .6s;
    -ms-animation-delay: .6s;
}
.anim .anim-delay-8 {
    animation-delay: .8s;
    -webkit-animation-delay: .8s;
    -ms-animation-delay: .8s;
}
.anim .anim-delay-12 {
    animation-delay: 1.2s;
    -webkit-animation-delay: 1.2s;
    -ms-animation-delay: 1.2s;
}
.anim .anim-delay-16 {
    animation-delay: 1.6s;
    -webkit-animation-delay: 1.6s;
    -ms-animation-delay: 1.6s;
}
@keyframes r2l {
    0% {
        transform: translateX(50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
@-webkit-keyframes r2l {
    0% {
        -webkit-transform: translateX(50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        opacity: 1;
    }
}
@-ms-keyframes r2l {
    0% {
        -ms-transform: translateX(50px);
        opacity: 0;
    }
    100% {
        -ms-transform: translateX(0);
        opacity: 1;
    }
}
@keyframes l2r {
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}
@-webkit-keyframes l2r {
    0% {
        -webkit-transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
        opacity: 1;
    }
}
@-ms-keyframes l2r {
    0% {
        -ms-transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        -ms-transform: translateX(0px);
        opacity: 1;
    }
}
@keyframes b2t {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@-webkit-keyframes b2t {
    0% {
        -webkit-transform: translateY(50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}
@-ms-keyframes b2t {
    0% {
        -ms-transform: translateY(50px);
        opacity: 0;
    }
    100% {
        -ms-transform: translateY(0);
        opacity: 1;
    }
}

