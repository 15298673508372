@charset 'utf-8';

/* CSS Document */
@import url('//at.alicdn.com/t/font_2323319_szmk2o1vzj.css');
//SCSS全局变量
// 默认移动端设计稿宽度

//默认颜色
$baseColor: #31a3ad;
$baseDevice: 750;
// 前端切图移动端默认正常显示尺寸，默认为设计稿的一半
$device: $baseDevice / 2;
// 默认html font-size
$baseFontSize: 100px;
// scss function

@function bfb($target, $context) {
    @return $target/$context * 100%;
}

@mixin baseImg() {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
}

@function calc-percent($target, $context) {
    @return $target/$context * 100%;
}

@function px2rem($px, $base-font-size: $baseDevice / $device * $baseFontSize) {
    @if (unitless($px)) {
        @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels for you";
        @return px2rem($px + 0px); // That may fail.
    }
    @else if (unit($px)==rem) {
        @return $px;
    }
    @return ($px / $base-font-size) * 1rem;
}

//透明
@mixin touming($o: 70) {
    opacity: $o/100;
    -webkit-opacity: $o/100;
    filter: alpha(opacity=$o);
}

@mixin transition($time: .2s) {
    -webkit-transition: $time ease all;
    -moz-transition: $time ease all;
    transition: $time ease all;
}

@mixin articleReset($fontsize: 14px, $lineHieght: 30px) {
    font-size: $fontsize;
    p {
        margin-bottom: $lineHieght/2;
    }
    img {
        max-width: 100%;
        height: auto !important;
    }
    ul {
        list-style: disc;
        li {
            list-style: disc;
        }
    }
    ol {
        list-style: decimal;
        li {
            list-style: decimal;
        }
    }
}

// 多行省略
@mixin multiEllipsis($line: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}

/* reset.css V1.6  Start*/

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ul,
ol,
li,
form,
button,
input,
textarea,
th,
td {
    font-weight: normal;
    margin: 0;
    padding: 0;
}

body,
button,
input,
select,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
i {
    font: normal 12px BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

ul,
ol,
li {
    list-style: none;
}

a {
    text-decoration: none;
    color: #333;
}

a:hover {
    text-decoration: none;
}

img {
    vertical-align: top;
    border: 0;
}

button,
input,
textarea {
    font-size: 100%;
    vertical-align: middle;
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

div,
a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html {
    font-size: $baseFontSize;
}

@media screen and (min-width: 320px) {
    html {
        font-size: (320/$device) * $baseFontSize;
    }
}

@media screen and (min-width: 360px) {
    html {
        font-size: (360/$device) * $baseFontSize;
    }
}

@media screen and (min-width: 375px) {
    html {
        font-size: (375/$device) * $baseFontSize;
    }
}

@media screen and (min-width: 480px) {
    html {
        font-size: (480/$device) * $baseFontSize;
    }
}

@media screen and (min-width: 640px) {
    html {
        font-size: (640/$device) * $baseFontSize;
    }
}

@media screen and (min-width: 750px) {
    html {
        font-size: (750/$device) * $baseFontSize;
    }
}

.clearfloat:after {
    font-size: 0;
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: ' ';
}

.clearfloat {
    *zoom: 1;
}

.clearboth {
    clear: both;
}

.wp {
    width: 1000px;
    margin: 0 auto;
}

.checkBrowser {
    font-size: 14px;
    line-height: 40px;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 50%;
    width: 700px;
    height: 40px;
    margin-left: -360px;
    padding: 0 10px;
    color: #fff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #e6212a;
    a {
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        text-align: center;
        color: #333;
        border-radius: 50%;
        background-color: #fff;
    }
}


/* reset.css V1.6  End */

// page结构以及CSS样式
// *******************
// ********************
// ********************
// HTML结构
// <div class="paged">
//  <a href="#">首页</a>
//  <a class="prev">上一页</a>
//  <span class="current">1</span>
//  <a href="#">2</a>
//  <a class="next" href="#">下一页</a>
//  <a href="#">尾页</a>
// </div>
// ********************
// ********************
// ********************
// CSS样式

// page结构以及CSS样式
.paged {
    font-size: 0;
    text-align: center;
}

.paged a,
.paged .p_info,
.paged .current {
    display: inline-block;
    vertical-align: top;
    margin: 5px 10px;
    padding: 0 6px;
    height: 20px;
    line-height: 20px;
    font-size: 18px;
    color: #333;
    font-family: Arial;
    transition: all .3s;
    @media screen and (max-width: 1440px) {
        margin: 4px 8px;
        font-size: 16px;
    }
    @media screen and (max-width: 750px) {
        margin: 3px 5px;
        font-size: 13px;
    }
}

.paged .prev,
.paged .next {
    font-size: 12px;
    font-family: 'iconfont';
    color: #666;
    &:before {
        content: '\e935';
        display: inline-block;
        vertical-align: top;
    }

}

.paged .prev {
    &:before {
        transform: rotate(180deg);
    }
}

.paged .current,
.paged a:hover {
    color: $baseColor;
}

//栏目页导航条

.inside-menu {
    background-color: #fff;
    .inside-swiper {
        overflow: hidden;
        .swiper-wrapper {
            font-size: 0;
        }
        .swiper-slide {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: auto;
            a {
                text-align: center;
                box-sizing: border-box;
                padding: px2rem(38px) px2rem(52px);
                font-size: px2rem(16px);
                position: relative;
                display: block;
                color: #000;
                transition: all .2s ease;
                @media screen and (max-width: 700px) {
                    padding: 18px 25px;
                    font-size: 14px;
                }
            }
            &.on,
            &:hover {

                a {
                    color: $baseColor;
                }
            }

        }
        .swiper-slide:after {
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            content: '' ;
            height: 8px;
            border-left: 1px solid rgba(0, 0, 0, .2);
        }
        .swiper-slide:nth-last-of-type(1):after {
            height: 0px;
        }
    }
}


.wp1600 {
    max-width: 1600px;
    margin: 0 auto;
    box-sizing: border-box;
    @media screen and (max-width: 1600px) {
        padding: 0 px2rem(52px);
    }
    @media screen and (max-width: 1000px) {
        padding: 0 px2rem(30px);
    }
}

.wp1500 {
    max-width: 1500px;
    margin: 0 auto;
    box-sizing: border-box;
    @media screen and (max-width: 1500px) {
        padding: 0 px2rem(52px);
    }
    @media screen and (max-width: 1000px) {
        padding: 0 px2rem(30px);
    }
}

.wp1136 {
    max-width: 1136px;
    margin: 0 auto;
    box-sizing: border-box;
    @media screen and (max-width: 1136px) {
        padding: 0 px2rem(52px);
    }
    @media screen and (max-width: 1000px) {
        padding: 0 px2rem(30px);
    }
}

//弹窗页面的css
.article-swiper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    touch-action: none;
    z-index: 8888;
    .swiper-zoom-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
    }
    .swiper-pagination {
        bottom: 1em;
        color: #fff;
    }
    .swiper-slide {
        img {
            max-width: 95%;
            max-height: calc(100% - 6em);
            vertical-align: middle;
        }
        p {
            position: absolute;
            top: 1em;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            color: #fff;
            z-index: 22;
        }
    }
}


