.customer-container-box {
    display: none;
}
.customer-on {
    display: block;
    animation-name: b2t;
    -webkit-animation-name: b2t;
    -ms-animation-name: b2t;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-duration: .5s;
    -webkit-animation-duration: .5s;
    -ms-animation-duration: .5s;
}
@keyframes b2t {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@-webkit-keyframes b2t {
    0% {
        -webkit-transform: translateY(20px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}
@-ms-keyframes b2t {
    0% {
        -ms-transform: translateY(20px);
        opacity: 0;
    }
    100% {
        -ms-transform: translateY(0);
        opacity: 1;
    }
}
.customer-content {
    display: block;
    padding: px2rem(100px) 0 px2rem(114px);
    background-color: #fff;
    box-sizing: border-box;
    @include articleReset(16px, 28px);
    color: #666;
    @media screen and (max-width: 750px) {
        padding: px2rem(60px) 0 px2rem(80px);
    }
    .wp1500 {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 750px) {
            display: block;
        }
        .left {
            order: 1;
            max-width: 672px;
            width: 100%;
            margin-right: 102px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 150px;
            box-sizing: border-box;
            @media screen and (max-width: 1260px) {
                flex: 1;
            }
            @media screen and (max-width: 750px) {
                margin-right: 0px;
                max-width: none;
                padding-left: 0px;
            }
            .title {
                position: relative;
                font-size: 30px;
                line-height: 30px;
                color: #333;
                font-weight: bold;
                @media screen and (max-width: 750px) {
                    font-size: px2rem(40px);
                    line-height: px2rem(40px);
                    text-align: center;
                }
            }
            .en {
                font-size: 13px;
                line-height: 20px;
                color: #999;
                text-transform: uppercase;
                @media screen and (max-width: 750px) {
                    font-size: px2rem(24px);
                    line-height: px2rem(34px);
                    text-align: center;
                }
            }
            .dsc {
                margin-top: px2rem(20px);
                position: relative;
                font-size: 14px;
                line-height: 30px;
                color: #888;
                @media screen and (max-width: 750px) {
                    content: none;
                    margin-left: 0px;
                    font-size: px2rem(26px);
                    line-height: px2rem(46px);
                    color: #666;
                }
            }
            .btn {
                display: inline-block;
                margin-top: px2rem(40px);
                text-align: center;
                height: px2rem(58px);
                line-height: px2rem(58px);
                width: px2rem(58px);
                background: linear-gradient(#2f82ac, #51a5b1);
                @media screen and (max-width: 750px) {
                    display: none;
                }
            }
            .btn:before {
                font-size: px2rem(20px);
                color: #fff;
            }

        }
        .right {
            order: 2;
            flex: 1;
            display: flex;
            align-items: center;
            @media screen and (max-width: 750px) {
                margin-top: px2rem(40px);
            }
            .img {
                width: 100%;
                position: relative;
                padding-bottom: (393 / 743) * 100%;
                overflow: hidden;
                cursor: pointer;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    height: auto;
                    transition: all .3s;
                }
            }
        }
        .btn2 {
            display: none;
            margin-top: px2rem(40px);
            text-align: center;
            height: px2rem(58px);
            line-height: px2rem(58px);
            width: px2rem(58px);
            background: linear-gradient(#2f82ac, #51a5b1);
            //@media screen and (max-width: 750px) {
            //    display: none;
            //}
            @media screen and (max-width: 750px) {
                display: inline-block;
            }
        }
        .btn2:before {
            font-size: px2rem(20px);
            color: #fff;
        }
    }
    .wp1500:hover {
        .btn {
            background: linear-gradient($baseColor, $baseColor) !important;
        }
        .right {
            .img {
                img {
                    transform: translate(-50%, -50%) scale(1.1);
                }
            }
        }
    }
}

.customer-container-box .customer-content:nth-child(odd) {
    background-color: #ebebeb;
    .wp1500 {
        .left {
            order: 2;
            margin-right: 0px;
            padding-left: 0px;
            padding-right: 150px;
            @media screen and (max-width: 750px) {
                padding-right: 0px;
            }
        }
        .right {
            order: 1;
            margin-right: 84px;
            @media screen and (max-width: 750px) {
                margin-right: 0px;
            }
        }
    }
}
