
.header {
    //栏目头部
    width: 100%;
    .header-web-tree {
        position: fixed;
        top: 0px;
        width: 100%;
        margin: 0 auto !important;
        height: 118px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 100;
        box-sizing: border-box;
        background: url(../images/navbk_02.png) center top;
        background-size: cover;
        padding: 0 93px;
        .header-right {
            display: flex;
            height: 100%;
        }
        .header-nav {
            height: 100%;
            margin-left: 100px;
            .header-ul {
                display: flex;
                height: 100%;
                .header-li:nth-of-type(1) {
                    margin-left: 0px !important;
                }
                .header-li {
                    position: relative;
                    text-align: center;
                    margin-left: 44px;
                    height: 100%;
                    box-sizing: border-box;
                }
                .header-li:before {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -22px;
                    content: '';
                    width: 1px;
                    height: 7px;
                    background-color: #5b5a59;
                    @media screen and (max-width: 1400px) {
                        right: -15px;
                    }
                    @media screen and (max-width: 1260px) {
                        right: -10px;
                    }
                    @media screen and (max-width: 1152px) {
                        right: -8px;
                    }
                    @media screen and (max-width: 1100px) {
                        right: -5px;
                    }
                }
                .header-li:nth-last-of-type(1):before {
                    content: none;
                }
                .header-li a {
                    display: inline-block;
                    height: 100%;
                    font-size: 16px;
                    text-align: center;
                    white-space: nowrap;
                    line-height: 118px;
                    color: #fff;
                    font-family: "苹方 中等";
                }
                .header-li a:hover {
                    color: $baseColor;
                }
                .on {
                    a {
                        color: $baseColor;
                    }
                }
            }
        }
        .header-search {
            margin-left: 46px;
            position: relative;
            display: flex;
            align-items: center;
            .header-btn {
                cursor: pointer;
                position: relative;
            }
            .header-search-form {
                animation-name: r2l;
                -webkit-animation-name: r2l;
                -ms-animation-name: r2l;
                animation-timing-function: ease;
                -webkit-animation-timing-function: ease;
                -ms-animation-timing-function: ease;
                animation-fill-mode: forwards;
                -webkit-animation-fill-mode: forwards;
                -ms-animation-fill-mode: forwards;
                animation-duration: .3s;
                -webkit-animation-duration: .3s;
                -ms-animation-duration: .3s;
            }
        }
        .header-search:hover {
            .header-search-form {
                display: block;
            }
        }
        //二级菜单
        .submenu {
            position: absolute;
            top: 118px;
            width: 100%;
            .show {
                opacity: 1;
                visibility: visible;
            }
            &-item {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                left: bfb(440, 1920);
                display: flex;
                padding: bfb(35, 1600) 0 bfb(55, 1600);
                width: bfb(675, 1600);
                background-color: #f9f9f9;
                &-left {
                    width: bfb(280, 675);
                    border-left: 1px solid #e6e5e3;
                    > ul {
                        padding-left: bfb(55, 280);
                        padding-right: bfb(40, 280);
                        font-size: 16px;
                        line-height: 40px;
                        > li {
                            @include multiEllipsis(1);
                            > a {
                                color: #000;
                                width: 100%;
                                display: inline-block;
                            }
                        }
                        li:hover {
                            > a {
                                color: #666;
                            }
                        }
                    }
                }
                &-right {
                    width: bfb(395, 675);
                    .img-div {
                        margin: 0 auto;
                        width: bfb(280, 395);
                        .img-box {
                            position: relative;
                            padding-top: bfb(180, 280);
                            overflow: hidden;
                            .img {
                                @include baseImg();
                            }
                        }
                    }
                }
            }


            .submenu-item:nth-of-type(2) {

                .submenu-item-left {
                    border-left: none;
                    border-right: 1px solid #e6e5e3;
                }
            }


            &-item:nth-of-type(2) {
                left: bfb(350, 1600);
            }
            &-item:nth-of-type(3) {
                flex-direction: row-reverse;
                left: bfb(400, 1600);

            }
            &-item:nth-of-type(4) {
                flex-direction: row-reverse;
                left: bfb(450, 1600);

            }
            &-item:nth-of-type(5) {
                flex-direction: row-reverse;
                left: bfb(500, 1600);

            }

            &-item:nth-of-type(6) {
                flex-direction: row-reverse;
                left: bfb(550, 1600);

            }

            &-item:nth-of-type(7) {
                flex-direction: row-reverse;
                left: bfb(700, 1600);

            }

            &-item:nth-of-type(8) {
                flex-direction: row-reverse;
                left: bfb(800, 1600);

            }
        }
    }
    @keyframes r2l {
        0% {
            transform: translateX(50px);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }
    @-webkit-keyframes r2l {
        0% {
            -webkit-transform: translateX(50px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            opacity: 1;
        }
    }
    @-ms-keyframes r2l {
        0% {
            -ms-transform: translateX(50px);
            opacity: 0;
        }
        100% {
            -ms-transform: translateX(0);
            opacity: 1;
        }
    }
    //搜索框
    .header-search-form {
        display: none;
        position: absolute;
        right: -16px;
        bottom: -6px;
        width: 240px;
        background-color: #f5f5f5;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 40px;
        transition: all .3s;
        z-index: 6;
        form {
            display: flex;
        }
        .input {
            flex: 1;
            min-width: 0;
        }
        input,
        button {
            display: block;
            height: 40px;
            line-height: 40px;
            outline: none;
            background: none;
            border: none;
        }
        input {
            padding-left: 20px;
            width: 100%;
            color: #555;
            box-sizing: border-box;
        }
        button {
            padding: 0 16px;
            font-size: 18px;
            color: #333;
            cursor: pointer;
            transition: all .3s;
            button:hover {
                color: #e6212a;
            }
        }
        ::-webkit-input-placeholder {
            color: #666;
        }
        ::-moz-placeholder {
            color: #666;
        }
        :-ms-input-placeholder {
            color: #666;
        }
    }
    //手机版头部
    .header-mobile {
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100px;
        padding: px2rem(28px);
        box-sizing: border-box;
        z-index: 100;
        background: url(../images/navbk_02.png) center top;
        background-size: cover;
        @media screen and (max-width: 500px) {
            height: 60px;
        }
        .header-mobile-logo {
            img {
                display: block;
                width: px2rem(159px);
                min-width: 100px;
                height: auto;
            }
        }
        .header-mobile-right {
            .header-search-form {
                min-width: 160px;
                .icon-sousuo {
                    font-size: 16px;
                }
            }
            .btn {
                cursor: pointer;
            }
            .icon-daohang {
                margin-left: px2rem(15px);
                color: #fff;
            }
            .icon-daohang:before {
                font-size: px2rem(45px);
                content: '\e608';
                transition: all .3s;
                color: #fff;
            }
            .active:before {
                content: '\e615';
                font-size: px2rem(45px) !important;
                //@media screen and (max-width: 500px) {
                //    font-size: 20px !important;
                //}
            }
        }
    }
    //web下拉导航
    .header-index-mobile {
        position: absolute;
        .header-mobile-right {
            .icon-sousuo {
                color: #fff;
            }
            .icon-daohangmoren {
                color: #fff;
            }
        }
    }
    .header-img img {
        display: block;
        width: 100%;
    }
    .header-mobile-img {
        display: none;
        img {
            display: block;
            width: 100%;
        }
    }
    @media screen and (max-width: 1600px) {
        .header-web-tree {
            padding: 0 60px;
            .header-right {
                .header-nav {
                    .header-ul {
                        .header-li {
                            margin-left: 45px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1500px) {
        .header-web-tree {
            padding: 0 100px;
        }
    }
    @media screen and (max-width: 1400px) {
        .header-web-tree {
            padding: 0 60px;
            .header-right {
                .header-nav {
                    .header-ul {
                        .header-li {
                            margin-left: 30px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1300px) {
        .header-web-tree {
            padding: 0 20px;
        }
    }
    @media screen and (max-width: 1260px) {
        .header-web-tree {
            padding: 0 20px;
            .header-right {
                .header-nav {
                    .header-ul {
                        .header-li {
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1152px) {
        .header-web-tree {
            padding: 0 20px;
            .header-right {
                .header-nav {
                    .header-ul {
                        .header-li {
                            margin-left: 16px;
                        }
                    }
                }
                .header-search {
                    margin-left: 40px;
                }
            }
        }
    }
    @media screen and (max-width: 1100px) {
        .header-web-tree {
            padding: 0 10px;
            .header-right {
                .header-nav {
                    margin-left: 30px;
                    .header-ul {
                        .header-li {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1000px) {
        .header-web {
            display: none;
        }
        .header-web-tree {
            display: none;
        }
        .header-img {
            display: none;
        }
        .header-mobile {
            display: flex;
        }
        .header-mobile-img {
            display: block;
        }
        .header-mobile-right {
            display: flex;
            justify-content: left;
            align-items: center;
            .header-search-position {
                width: px2rem(200px);
                min-width: 140px;
                top: px2rem(120px);
                right: px2rem(70px);
                form {
                    .icon-sousuo {
                        margin-right: 0px !important;
                    }
                }
            }
            .icon-daohangmoren {
                display: inline-block;
            }
        }
    }
}
.nav-mobile {
    position: absolute;
    width: 100%;
    top: px2rem(270px);
    right: 0;
    bottom: 0;
    z-index: 103;
    background: #ebebeb;
    box-sizing: border-box;
    overflow: hidden;
    transform: translateX(100%);
    transition: transform .4s ease;
    @media screen and (max-width: 500px) {
        top: 160px;
    }
    &.anim {
        transform: translateX(0);
    }
    &-con {
        display: none;
        position: fixed;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 8889;
        transition: transform .4s ease;
        //@media screen and (max-width: 750px) {
        //    top: 100px;
        //}
        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .4);
            z-index: 1;
        }
        &.anim {
            .nav-mobile-search {
                transform: translateX(0) !important;
            }
        }
    }
    &-back {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 px2rem(50px);
        width: 100%;
        height: px2rem(100px);
        background-color: #232323;
        color: #fff;
        box-sizing: border-box;
        @media screen and (max-width: 500px) {
            height: 60px;
        }
        span {
            position: relative;
            z-index: 1;
            font-size: 28px;
            line-height: px2rem(100px);
            color: #fff;
            @media screen and (max-width: 500px) {
                font-size: 20px;
                line-height: 60px;
            }
        }
        .fh {
            margin-left: px2rem(20px);
            @media screen and (max-width: 500px) {
                margin-left: 5px;
            }
        }
    }
    &-search {
        position: absolute;
        right: 0;
        top: px2rem(100px);
        background-color: #ebebeb;
        height: px2rem(170px);
        display: flex;
        z-index: 104;
        box-sizing: border-box;
        align-items: center;
        padding: 0 px2rem(50px);
        width: 100%;
        transform: translateX(100%);
        transition: transform .4s ease;
        @media screen and (max-width: 500px) {
            height: 100px;
            top: 60px;
        }
        .box {
            //height: px2rem(90px);
            border: 1px solid #e2e2e2;
            border-radius: px2rem(45px);
            background-color: #fff;
            display: flex;
            padding: 0 px2rem(30px);
            width: 100%;
            box-sizing: border-box;
        }
        .input {
            flex: 1;
            overflow: hidden;
        }
        input[type="text"] {
            width: 100%;
            height: px2rem(90px);
            line-height: px2rem(90px);
            appearance: none;
            border: none;
            font-size: px2rem(26px);
            background: none;
        }
        button {
            width: px2rem(90px);
            height: px2rem(90px);
            border: none;
            appearance: none;
            //font-family: iconfont;
            display: block;
            background: none;
            font-size: px2rem(40px);
            color: #666;
            //&:before {
            //    content: '\e627';
            //}
        }
    }
    &-wp {
        background-color: #fff;
        position: relative;
        & > li {
            border-bottom: 1px solid #e5e5e5;
        }
    }
    &-title {
        position: relative;
        > a {
            position: relative;
            display: block;
            padding: 0 px2rem(30px) 0 px2rem(65px);
            width: 100%;
            line-height: px2rem(100px);
            font-size: px2rem(32px);
            color: #666;
            z-index: 2;
            backface-visibility: hidden;
            box-sizing: border-box;
        }
        &.open {
            .nav-mobile-child-btn i {
                &:before {
                    content: '\e937';
                }
            }
        }
    }
    &-child-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 px2rem(30px) 0 px2rem(65px);
        text-align: right;
        line-height: px2rem(100px);
        box-sizing: border-box;
        z-index: 1;
        i {
            font-size: px2rem(22px);
            color: #666;
            font-family: "iconfont";
            transition: all .3s;
            &:before {
                content: "\e918";
            }
        }
        + a {
            display: inline-block;
            width: calc(100% - 50px);
        }
    }
    .sub {
        display: none;
        border-top: 1px solid #e2e2e2;
        li {
            position: relative;
            overflow: hidden;
            &:not(:last-child) a {
                border-bottom: 1px solid #e2e2e2;
            }
            a {
                display: block;
                padding: 0 px2rem(30px) 0 px2rem(65px);
                line-height: px2rem(100px);
                height: px2rem(100px);
                font-size: px2rem(28px);
                color: #666;
                overflow: hidden;
                box-sizing: border-box;
            }
        }
    }
}
.header-tree-banner {
    overflow: hidden;
    .img-pc,
    .img-mobile {
        position: relative;
        padding-bottom: (698 / 1920) *100%;
        overflow: hidden;
        img {
            position: absolute;
            left: 0;
            top: 0;
            //transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
        }
    }
    .img-mobile {
        padding-bottom: (500 / 750) *100%;
    }
}
.banner-web {
    @media screen and (max-width: 1000px) {
        display: none;
    }
}
.banner-mobile {
    display: none;
    @media screen and (max-width: 1000px) {
        display: block;
    }
}