.contact-container {
    .map-container {
        padding: px2rem(80px) 0;
        background-color: #ebebeb;

        .wp1500 {
            .map-bk {
                width: 100%;
                background-color: #fff;
                display: flex;
                box-shadow: 0 0 80px 10px rgba(0, 0, 0, .1);
                @media screen and (max-width: 750px) {
                    display: block;
                }
            }
            .left {
                width: (667 / 1500) * 100%;
                box-sizing: border-box;
                //display: flex;
                //justify-content: center;
                //flex-direction: column;
                padding-left: 108px;
                padding-top: 80px;
                @media screen and (max-width: 1500px) {
                    padding-left: 22px;
                }
                @media screen and (max-width: 750px) {
                    padding: px2rem(22px);
                    width: 100%;
                }
                .name {
                    font-size: 24px;
                    line-height: 24px;
                    font-weight: bold;
                    color: #333;
                    @media screen and (max-width: 500px) {
                        font-size: 18px;
                        line-height: 18px;
                    }
                }
                .en {
                    margin-top: px2rem(8px);
                    margin-bottom: px2rem(30px);
                    font-size: 16px;
                    font-family: Arial;
                    line-height: 16px;
                    color: #b8b8b8;
                    text-transform: uppercase;
                    text-indent: 3px;
                    @media screen and (max-width: 500px) {
                        font-size: 13px;
                        line-height: 13px;
                    }
                }
                .same {
                    display: flex;
                    margin-bottom: px2rem(14px);
                    span:nth-of-type(1) {
                        display: block;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 30px;
                        color: #fff;
                        background: linear-gradient(#2f83ae, #52bac8);
                        @media screen and (max-width: 500px) {
                            width: 20px;
                            height: 20px;
                            line-height: 20px;
                            font-size: 12px;
                        }
                    }
                    .word {
                        flex: 1;
                        display: block;
                        margin-left: px2rem(8px);
                        font-size: 14px;
                        line-height: 30px;
                        color: #666;
                        @media screen and (max-width: 500px) {
                            line-height: 20px;
                            font-size: 13px;
                        }
                    }
                }
                .same:nth-last-of-type(1) {
                    @media screen and (max-width: 750px) {
                        margin-bottom: 0px;
                    }
                }
            }
            .right {
                flex: 1;
                padding: px2rem(22px);
                box-sizing: border-box;
                height: px2rem(436px);
                .map {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

}

.online-container {
    width: 100%;
    background-color: #fff;
    .online-content {
        padding: 78px 206px 99px;
        box-sizing: border-box;
        @media screen and (max-width: 1500px) {
            padding: px2rem(78px) px2rem(22px) px2rem(99px);
        }
        @media screen and (max-width: 500px) {
            padding: px2rem(78px) 0px px2rem(99px);
        }
        .name {
            font-size: 30px;
            line-height: 30px;
            font-weight: bold;
            color: #333;
            text-align: center;
            @media screen and (max-width: 500px) {
                font-size: 20px;
                line-height: 20px;
            }
        }
        .en {
            font-size: 18px;
            line-height: 18px;
            font-family: Arial;
            color: #888;
            margin-top: px2rem(9px);
            text-align: center;
            text-transform: uppercase;
            @media screen and (max-width: 500px) {
                font-size: 14px;
                line-height: 14px;
            }
        }
        .form {
            max-width: 1080px;
            width: 100%;
            margin: px2rem(25px) auto 0;
            .form-content {
                display: flex;
                width: 100%;
                .form-box {
                    width: 50%;
                    input {
                        outline: none;
                        width: 100%;
                        height: 55px;
                        border: none;
                        box-sizing: border-box;
                        background-color: #f5f5f5;
                        font-size: 14px;
                        text-indent: px2rem(20px);
                        color: #000;
                        @media screen and (max-width: 500px) {
                            height: 35px;
                        }
                    }
                    input::placeholder {
                        color: #000;
                    }
                }
                .form-box2 {
                    margin-left: px2rem(14px);
                }
            }
            .form-content2 {
                .form-box {
                    width: 33.3%;
                }
            }
            .form-ly {
                margin-top: px2rem(17px);
                padding: px2rem(13px) px2rem(22px);
                box-sizing: border-box;
                width: 100%;
                height: px2rem(167px);
                background-color: #f5f5f5;
                textarea {
                    width: 100%;
                    height: 100%;
                    outline: none;
                    resize: none;
                    border: none;
                    background-color: #f5f5f5;
                    font-size: 14px;
                    line-height: 30px;
                    color: #000;
                    overflow: hidden;
                    list-style: none;
                    @media screen and (max-width: 500px) {
                        line-height: 20px;
                    }
                }
                textarea::placeholder {
                    color: #000;
                }
            }
            .form-login {
                margin-top: px2rem(17px);
                display: flex;
                .captcha {
                    height: 57px;
                    width: (717 / 1080) * 100%;
                    @media screen and (max-width: 500px) {
                        height: 35px;
                    }
                }
                .btn {
                    flex: 1;
                    margin-left: px2rem(14px);
                    height: 57px;
                    background-color: #545454;
                    @media screen and (max-width: 500px) {
                        height: 35px;
                    }
                    .submit {
                        text-align: center;
                        a {
                            display: inline-block;
                            width: 100%;
                            height: 57px;
                            font-size: 16px;
                            line-height: 57px;
                            color: #fff;
                            @media screen and (max-width: 500px) {
                                height: 35px;
                                line-height: 35px;
                                font-size: 14px;
                            }
                        }
                        a:hover {
                            background-color: $baseColor;
                        }
                    }
                }
            }
        }
    }
}