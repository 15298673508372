.cpzx_info-container {
    padding: px2rem(80px) 0 px2rem(84px);
    background-color: #ebebeb;
    .cpzx-show {
        width: 100%;
        display: flex;
        padding: 48px 58px;
        box-sizing: border-box;
        background-color: #fff;
        @media screen and (max-width: 1500px) {
            padding: px2rem(50px) px2rem(30px);
        }

        @media screen and (max-width: 750px) {
            display: block;
        }
        .left {
            width: (881 / 1384) * 100%;
            @media screen and (max-width: 750px) {
                width: 100%;
            }
            .img {
                display: none;
                position: relative;
                width: 100%;
                padding-bottom: (543 / 881) *100%;
                transition: all .3s;
                animation-name: l2r;
                -webkit-animation-name: l2r;
                -ms-animation-name: l2r;
                animation-timing-function: linear;
                -webkit-animation-timing-function: linear;
                -ms-animation-timing-function: linear;
                animation-fill-mode: forwards;
                -webkit-animation-fill-mode: forwards;
                -ms-animation-fill-mode: forwards;
                animation-duration: .5s;
                -webkit-animation-duration: .5s;
                -ms-animation-duration: .5s;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    height: auto;
                }
            }
            .on {
                display: block;
            }
        }
        .right {
            flex: 1;
            padding: 60px 0px 16px 55px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media screen and (max-width: 1500px) {
                padding: 0 0 0 px2rem(30px);
            }
            @media screen and (max-width: 750px) {
                padding: 0;
                margin-top: px2rem(30px);
            }
            .info-show {
                width: 100%;
                padding-left: 36px;
                padding-right: 34px;
                box-sizing: border-box;
                @media screen and (max-width: 1500px) {
                    padding: 0px;
                }
                .info-title {
                    font-size: 30px;
                    line-height: 30px;
                    color: #333;
                    padding-bottom: px2rem(27px);
                    margin-bottom: px2rem(26px);
                    border-bottom: 1px dashed #d6d6d6;
                    box-sizing: border-box;
                    @media screen and (max-width: 750px) {
                        font-size: 22px;
                        line-height: 22px;
                    }
                }
                .info-dsc {
                    font-size: 14px;
                    line-height: 20px;
                    color: #777;
                    @include articleReset(14px, px2rem(38px));
                    p {
                        font-size: 14px;
                        line-height: 20px;
                        color: #777;
                        margin-bottom: px2rem(19px);
                    }
                }
            }
            .img-selector {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -1.5px;
                width: 100%;
                .img {
                    width: 33.3%;
                    padding: 0 1.5px;
                    box-sizing: border-box;
                    cursor: pointer;
                    .img-box {

                        position: relative;
                        border: 3px solid #fff;
                        padding-bottom: (543 / 881) *100%;
                        box-sizing: border-box;
                        transition: all .2s;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .on {
                    .img-box {
                        border: 3px solid $baseColor;
                    }
                }
            }
        }
    }
    .cpzx-info {
        margin-top: 33px;
        display: flex;
        @media screen and (max-width: 750px) {
            display: block;
        }
        .info-left {
            padding: 53px 110px 56px;
            background-color: #fff;
            @media screen and (max-width: 1500px) {
                padding: px2rem(50px) px2rem(30px);
            }
            .name {
                font-size: 30px;
                line-height: 30px;
                color: #333;
                text-align: center;
                @media screen and (max-width: 750px) {
                    font-size: 22px;
                    line-height: 22px;
                }
            }
            .en {
                font-size: 13px;
                line-height: 17px;
                color: #999;
                text-align: center;
                text-transform: uppercase;
            }
            .cpzx-content {
                @include articleReset(14px, 26px);
                font-size: 14px;
                line-height: 26px;
                color: #666;
                max-width: 960px;
                width: 100%;
                margin: px2rem(34px) auto 0;
            }
        }
        .info-right {
            flex: 1;
            background-color: #fff;
            box-shadow: inset 10px 0 20px -18px rgba(0, 0, 0, .6);
            box-sizing: border-box;
            padding: 58px 32px;
            //height: 100%;
            @media screen and (max-width: 1500px) {
                padding: 50px px2rem(30px);
            }
            @media screen and (max-width: 750px) {
                margin-top: 33px;
                box-shadow: none;
            }
            .name {
                font-size: 24px;
                line-height: 24px;
                color: #0f0f0f;
                text-align: center;
            }
            .en {
                margin-top: 4px;
                font-size: 13px;
                line-height: 13px;
                color: #999;
                text-align: center;
                margin-bottom: px2rem(35px);
                text-transform: uppercase;
            }
            .img-box {
                display: block;
                width: 100%;
                margin-bottom: px2rem(44px);
                .img {
                    position: relative;
                    width: 100%;
                    padding-bottom: (155 / 252) * 100%;
                    overflow: hidden;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        height: auto;
                        transition: all .3s;
                    }
                }
                .name {
                    font-size: 18px;
                    line-height: 18px;
                    color: #333;
                    margin-top: 13px;
                }
            }

            .img-box:hover {
                .img {
                    img {
                        transform: translate(-50%, -50%) scale(1.1);
                    }
                }
                .name {
                    color: $baseColor;
                }
            }

        }
    }
}
@keyframes l2r {
    0% {
        transform: translateX(-20px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
@-webkit-keyframes l2r {
    0% {
        -webkit-transform: translateX(-20px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        opacity: 1;
    }
}
@-ms-keyframes l2r {
    0% {
        -ms-transform: translateX(-20px);
        opacity: 0;
    }
    100% {
        -ms-transform: translateX(0);
        opacity: 1;
    }
}
