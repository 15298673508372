.news_info-container {
    background-color: #ebebeb;
    padding: px2rem(50px) 0 px2rem(60px);
}
.news_info-content {
    display: flex;
    @media screen and (max-width: 750px) {
        display: block;
    }
    .left-container {
        width: 100%;
        @media screen and (max-width: 750px) {
            width: 100%;
        }
    }
    .right-container {
        width: (358 / 1500) * 100%;
        margin-left: 20px;
        @media screen and (max-width: 750px) {
            margin-top: 40px;
            margin-left: 0px;
            width: 100%;
        }
        .hot-news {
            background-color: #fff;
            padding: 33px 35px 44px 28px;
            margin-bottom: 20px;
            @media screen and (max-width: 1500px) {
                padding: 20px 10px 25px;
            }
            @media screen and (max-width: 750px) {
                padding: 20px 30px 25px;
            }
            .name {
                font-size: 24px;
                line-height: 24px;
                margin-bottom: 6px;
                text-align: center;
            }
            .en {
                text-align: center;
                font-size: 16px;
                line-height: 16px;
                font-family: Arial;
                color: #4094af;
                text-transform: uppercase;
            }
            .hot-list {
                margin-top: 18px;
                .list-li {
                    display: inline-block;
                    padding-bottom: 16px;
                    border-bottom: 1px dashed #d9d9d9;
                    margin-bottom: 18px;
                    width: 100%;
                    .title {
                        position: relative;
                        font-size: 13px;
                        line-height: 13px;
                        color: #666;
                        height: 13px;
                        overflow: hidden;
                        padding-left: 10px;
                    }
                    .title:before {
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: #8d8d8d;
                        left: 0px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    .time {
                        margin-top: 6px;
                        font-size: 14px;
                        line-height: 14px;
                        color: #4094af;
                        font-family: Arial;
                        padding-left: 10px;
                    }
                }
                .list-li:hover {
                    .title {
                        color: $baseColor;
                    }
                }
                .list-li:nth-last-of-type(1) {
                    border: none;
                    margin-bottom: 0px;
                    padding-bottom: 0px;
                }
            }
        }

    }
}
.news-info {
    background-color: #fff;
    padding: 57px 30px 105px;
    margin-bottom: 38px;
    @media screen and (max-width: 750px) {
        padding: 30px px2rem(30px) px2rem(70px);
    }
}
.news-title {
    max-width: 980px;
    margin: 0 auto;
    border-bottom: 1px dashed #c0bfbf;
    text-align: center;
    padding-bottom: px2rem(32px);
    h2 {
        font-size: 30px;
        line-height: 30px;
        font-weight: bold;
        color: #555;
        margin-bottom: px2rem(25px);
    }
    .tips {
        font-size: 13px;
        line-height: 13px;
        color: #666;
    }
    @media screen and (max-width: 640px) {
        box-sizing: border-box;
        padding-left: px2rem(20px);
        padding-right: px2rem(20px);
        h2 {
            font-size: 20px;
        }
    }
}
.news-article {
    padding-top: 24px;
    font-size: 14px;
    line-height: 26px;
    color: #666;
    max-width: 980px;
    margin: 0 auto;
    @include articleReset(14px, 26px);
    @media screen and (max-width: 640px) {
        box-sizing: border-box;
        padding-left: px2rem(20px);
        padding-right: px2rem(20px);
    }
}
.news-article2 {
    max-width: 100%;
}
.news-paged {
    background-color: #424242;
    li {
        float: left;
        border-left: 1px solid #5d5d5d;
        width: 33.333%;
        box-sizing: border-box;
    }

    li:nth-of-type(1) {
        border-left: 1px solid #ebebeb;
    }
    a {
        display: block;
        text-align: center;
        height: 55px;
        line-height: 55px;
        overflow: hidden;
        font-size: 14px;
        color: #fff;
        &:hover {
            background: linear-gradient(#2f83ae, #52bac8);
        }
    }
    &-prev {
        a:before {
            display: inline-block;
            vertical-align: top;
            font-family: 'iconfont';
            margin-right: 20px;
            content: '\e63b';
        }
    }
    &-list {
        a:before {
            display: inline-block;
            vertical-align: top;
            margin-right: 16px;
            font-family: 'iconfont';
            content: '\e63c';
            font-size: 20px;
        }
    }
    &-next {
        a:after {
            display: inline-block;
            vertical-align: top;
            margin-left: 20px;
            font-family: 'iconfont';
            content: '\e919';
        }
    }
    @media screen and (max-width: 640px) {
        &-prev,
        &-list {
            a:before {
                margin-right: px2rem(20px);
            }
        }
        &-next {
            a:after {
                margin-left: px2rem(20px);
            }
        }
    }
}