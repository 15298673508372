.market-container {
    padding: px2rem(53px) 0 px2rem(86px);
    background-color: #ebebeb;
    .market-bk {
        background-size: cover !important;
        width: 100%;
        height: 357px;
        @media screen and (max-width: 750px) {
            height: 300px;
        }
        .bk-content {
            width: 100%;
            max-width: 720px;
            padding: px2rem(88px) px2rem(30px) 0px;
            margin: 0 auto;
            box-sizing: border-box;
            @media screen and (max-width: 750px) {
                padding: 40px px2rem(30px) 0;
            }
            .title {
                font-size: 36px;
                line-height: 36px;
                font-weight: bold;
                color: #fff;
                text-align: center;

                @media screen and (max-width: 750px) {
                    font-size: 28px;
                    line-height: 28px;
                }
            }
            .dsc {
                margin-top: px2rem(17px);
                font-size: 14px;
                line-height: 24px;
                color: #fff;
                text-align: center;
                @media screen and (max-width: 400px) {
                    font-size: 13px;
                    line-height: 22px;
                }
            }
        }
    }
    .market-content {
        position: relative;
        width: (1320 / 1500) * 100%;
        margin: -90px auto 0;
        padding: 60px 57px;
        box-sizing: border-box;
        background-color: #fff;
        @media screen and (max-width: 1260px) {
            padding: px2rem(30px);
        }
        @media screen and (max-width: 750px) {
            margin: -60px auto 0;

            padding: px2rem(50px);
        }

        @media screen and (max-width: 380px) {
            margin: -40px auto 0;
        }

        .col-top {
            margin: -19px;
        }
        .col-bottom {
            margin: 36px -17px 0;

            @media screen and (max-width: 750px) {
                margin: 19px -19px -19px;
            }
        }
        .col-same {
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            .col-6 {
                width: 50%;
                padding: 19px;
                box-sizing: border-box;
                @media screen and (max-width: 750px) {
                    width: 100%;
                }
                .img {
                    position: relative;
                    width: 100%;
                    padding-bottom: (260 / 579) * 100%;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        height: auto;
                    }
                }
                .dsc-word {
                    max-width: 380px;
                }
            }
            .col-4 {
                width: 33.3%;
                padding: 17px;
                box-sizing: border-box;
                @media screen and (max-width: 750px) {
                    padding: 19px;
                    width: 100%;
                }

                .img {
                    position: relative;
                    width: 100%;
                    padding-bottom: (214 / 374) * 100%;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        height: auto;
                    }
                }

                .dsc-word {
                    max-width: 264px;
                }
            }

            .col-same-box {
                .dsc-box {
                    position: relative;
                    margin-top: -45px;
                    @media screen and (max-width: 1260px) {
                        margin-top: -25px;
                    }
                    .circle {
                        width: 82px;
                        height: 82px;
                        background: linear-gradient(#2f83ae, #52bac8);
                        font-size: 18px;
                        font-family: Arial;
                        color: #fff;
                        text-align: center;
                        line-height: 90px;
                        border-radius: 50%;
                        margin: 0 auto;

                        @media screen and (max-width: 1260px) {
                            width: 50px;
                            height: 50px;
                            line-height: 50px;
                        }
                        @media screen and (max-width: 750px) {
                            font-size: 14px;
                        }
                    }
                    .dsc-name {
                        margin-top: 8px;
                        margin-bottom: 9px;
                        font-size: 18px;
                        line-height: 18px;
                        color: #333;
                        text-align: center;
                    }
                    .dsc-word {
                        width: 100%;

                        margin: 0 auto;
                        font-size: 14px;
                        line-height: 24px;
                        color: #888;
                        text-align: center;
                    }
                }
            }
        }
    }
}

